import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';

/** --------------------- IMPORT DE BIBLIOTECAS --------------------- */
import 'rsuite/dist/rsuite.min.css';                                                                
import { Button, Modal, Loader, Tooltip, Whisper } from 'rsuite';                       
import TrashIcon from '@rsuite/icons/Trash';         
import { useNavigate } from 'react-router-dom';                                                            
import Cookies from 'universal-cookie';         
import Resizer from "react-image-file-resizer";                                                    
/** --------------------- --------------------- --------------------- **/


/** --------------------- IMPORT DE IMAGENS --------------------- */
import IconIntercontentImg from '../../assets/images/icon_intercontent.png';                  
import IconAlertImg from '../../assets/images/icon_alert.png';                                
import ExamplePreParagraphImg from '../../assets/images/icon_preparagraph.png';                 
import ExampleFooterImg from '../../assets/images/icon_footer.png';                              
import ExampleStickyImg from '../../assets/images/icon_sticky.png';                              
import ExampleFlyingCarpetImg from '../../assets/images/icon_flyingcarpet.png';                              
/** --------------------- --------------------- ---------------- **/


/** --------------------- IMPORT DE FUNÇÕES/CONEXÕES PARA SALVAR DADOS --------------------- */
import { api } from '../../services/api';  
import { SiteInfoContext } from '../../contexts/SiteInfo';                                               
/** --------------------- --------------------- --------------------- --------------------- **/


/** --------------------- IMPORT DE HELPERS --------------------- */
import HeaderText from '../../helpers/HeaderText';         
import CallbackMessage from '../../helpers/CallbackMessage';
import Loading from '../../helpers/Loading';
import { createBlocoFromDominio } from '../../helpers/blocoFunction';
import {                                                                                            
    intercontentFillRate, 
    eCpmIntercontent, 
    alertFillRate, 
    eCpmAlert, 
    preParagraphFillRate, 
    eCpmPreParagraph, 
    footerFillRate, 
    eCpmFooter, 
    stickyFillRate, 
    eCpmSticky, 
    flyingFillRate, 
    eCpmFlying, 
    revenueShare 
} from '../../helpers/calculo'; 
/** --------------------- --------------------- ---------------- **/

import { FormEditSite, FormAddNewSite } from '../../Forms';

// Estilização
import styles from './second_page.module.scss';                                                    

let colorsArray = ['#D7263D', '#1B998B', '#F46036', '#403233', '#92898A', '#A18276', '#20A39E', '#23001E', '#DF7373', '#CC444B', '#00A1E4', '#DC0073', '#8332AC', '#35A7FF', '#030027', '#151E3F', '#AE8CA3', '#939196', '#AD7A99', '#5A716A', '#040404', '#00A878', '#FE5E41'];
let colorsUsedArray = [];

type siteData = {
    id: any;
    site_name: string;
    bloco: string;
    dominio: string;
    categoria: string;
    pageviews: number;
    logo: any;
    logourl: any;
    bgColor: string;
    fontColor: string;
    adstxt: string;
    mcm: number;
}[];

export default function MainContent() {
    const cookies = new Cookies();
    const token = cookies.get('token-acesso');

    const { saveCurrentStep, saveCurrentToken, currentToken, formatosSto, sitesSto, totalValueSto, tradicionalValueSto, ampValueSto, saveData } = useContext(SiteInfoContext);

    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false);        // Variável para abrir modal de editar Site
    const [openDeleteModal, setOpenDeleteModal] = useState(false);        // Variável para abrir modal de deletar Site 

    /** ----------------------------------- Variáveis principais da página ----------------------------------- */
    const [formatosArray, setFormatos] = useState([formatosSto]);

    const [siteName, setSiteName] = useState('');
    const [siteDominio, setSiteDominio] = useState('');
    const [siteCategoria, setSiteCategoria] = useState('');
    const [sitePageviews, setSitePageviews] = useState(0);
    const [errorImage, setErrorImage] = useState(false);
    const [siteColorTopbar, setSiteColorTopbar] = useState('#FFB300');
    const [siteColorText, setSiteColorText] = useState('#454849');
    const [tradicionalValue, setTradicionalValue] = useState(tradicionalValueSto);
    const [ampValue, setAmpValue] = useState(ampValueSto);

    const [intercontentFormat, setIntercontentFormat] = useState(false);
    const [alertFormat, setAlertFormat] = useState(false);
    const [preparagraphFormat, setPreparagraphFormat] = useState(false);
    const [footerFormat, setFooterFormat] = useState(false);
    const [stickyFormat, setStickyFormat] = useState(false);
    const [flyingFormat, setFlyingFormat] = useState(false);

    const [siteSigla, setSiteSigla] = useState('');
    const [sites, setSites] = useState([sitesSto] as unknown as siteData);
    const [logoFile, setLogoFile] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    
    const [totalValue, setTotalValue] = useState(totalValueSto);

    const [showForm, setShowForm] = useState(false);
    /** ----------------------------------- ----------------------------------- ----------------------------- **/


    /** ----------------------------- Variávei de edição de Sites ----------------------------- */
    const [editLogoUrl, setEditLogoUrl] = useState('');
    const [editErrorImage, setEditErrorImage] = useState(false);

    const [currentIdEditing, setCurrentIdEditing] = useState('');
    const [currentNameEditing, setCurrentNameEditing] = useState('');
    const [currentBlocoEditing, setCurrentBlocoEditiging] = useState(''); 
    const [currentDominioEditing, setCurrentDominioEditing] = useState('');
    const [currentCategoriaEditing, setCurrentCategoriaEditing] = useState('');
    const [currentPageviewsEditing, setCurrentPageviewsEditing] = useState(0);
    const [currentLogoEditing, setCurrentLogoEditing] = useState('');
    const [currentLogoFileEditing, setCurrentLogoFileEditing] = useState('');
    const [currentColorTopbarEditing, setCurrentColorTopbarEditing] = useState('');
    const [currentColorTextEditing, setCurrentColorTextEditing] = useState('');

    const [editButtonDisabled, setEditButtonDisabled] = useState(true);
    /** ----------------------------- ----------------------------- ----------------------------- */


    /** ----------------------------- Variáveis de hover nos sites ------------------------------ */
    const [currentHoverSiteName, setCurrentHoverSiteName] = useState('');
    const [currentHoverSiteDominio, setCurrentHoverSiteDominio] = useState('');
    const [currentHoverSitePageviews, setCurrentHoverSitePageviews] = useState(0);
    /** ----------------------------- ----------------------------- ----------------------------- */


    /** ---------------------- Variáveis de verificação ---------------------- */
    const [hasSiteName, setHasSiteName] = useState(true);
    const [hasSiteDominio, setHasSiteDominio] = useState(true);
    const [hasSiteCategoria, setHasSiteCategoria] = useState(true);
    const [hasSitePageviews, setHasSitePageviews] = useState(true);
    const [hasSiteLogo, setHasSiteLogo] = useState(true);
    /** ----------------------------------- ----------------------------------- **/


    /** ----------------------------------- Loading de visualização ----------------------------------- */
    const [loading, setLoading] = useState(true);
    const [loadingSite, setLoadingSite] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingEditingData, setLoadingEditingData] = useState(false);
    const [loadingDeleteData, setLoadingDeleteData] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    /** ----------------------------------- ----------------------------------- ----------------------- **/


    /** ----------------------------------- Variáveis para comparação e delete ----------------------------------- */
    const [deleteSiteIndex, setDeleteSiteIndex] = useState('');
    /** ----------------------------------- ----------------------------------- ----------------------------- **/


    /** ----------------------------------- Variáveis que armazena mensagem de Erro e Sucesso ----------------------------------- */
    const [errorMessage, setErrorMessage] = useState('');
    const [openErrorMessage, setOpenErrorMessage] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
    /** ----------------------------------- ----------------------------------- ------------------------------------ ----------- **/


    // Seta os valores vindo do Banco de Dados em suas repesctivas variáveis (ativada somente ao carregar a página) 
    useEffect(() => {
        saveCurrentStep(1);

        if(token && !currentToken) saveCurrentToken(token);

        api.get(`user/${currentToken ? currentToken : token}`).then((res) => {
            const datasites = res.data.result[0];
            const datauser = res.data.result[1];

            cookies.set('token-acesso', currentToken && currentToken !== '0' ? currentToken : token, {
                maxAge: 10000000
            });

            saveData(datauser.formatos.split(','), datasites, datauser.total_value, datauser.tradicional, datauser.amp);

            if(datauser) {
                setFormatos(datauser.formatos.split(','));
                setTradicionalValue(datauser.tradicional);
                setAmpValue(datauser.amp);
                setTotalValue(datauser.total_value);
            } else {
                navigate('/');
            }

            if(datasites) {
                setSites(datasites);

                datasites.forEach((item) => {
                    if(!item.logo) {
                        if(item.name) {
                            let name_split = item.name.split(' ');
    
                            if(name_split.length > 1) {
                                setSiteSigla(name_split[0][0] + name_split[1][0]);
                            } else {
                                setSiteSigla(name_split[0][0] + name_split[0][1]);
                            }
                        } else {
                            let bloco_split = item.bloco.split(' ');
    
                            if(bloco_split.length > 1) {
                                setSiteSigla(bloco_split[0][0] + bloco_split[1][0]);
                            } else {
                                setSiteSigla(bloco_split[0][0] + bloco_split[0][1]);
                            }
                        }
                    }
                })
            }
            
            setLoading(false);
        });

        for(let i = 0; i < sites.length; i++) {
            let randomNumber = Math.floor(Math.random() * colorsArray.length);

            colorsUsedArray.push(colorsArray[randomNumber]);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Verificando se as variáveis dentro do [] estão preenchidas (ativada sempre que alguma dessas variáveis mudar)
    useEffect(() => {
        if(siteName) {
            setHasSiteName(true);
        }

        if(siteDominio) {
            setHasSiteDominio(true);
        }

        if(siteCategoria) {
            setHasSiteCategoria(true);
        }

        if(sitePageviews) {
            setHasSitePageviews(true);
        }

        if(logoFile) {
            setHasSiteLogo(true);
        }
    }, [siteName, siteDominio, siteCategoria, sitePageviews, logoFile]);

    // Hook para alterar valor total toda vez que adicionar/remove um site
    useEffect(() => {
        if(sites.length === 0) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }

        // Variáveis utilizadas dentro desse hook que recebem o valor 0 de inicio
        let intercontentCalculo: any,
        alertCalculo: any,
        preParagraphCalculo: any,
        footerCalculo: any,
        stickyCalculo: any,
        flyingCalculo: any,
        sumPageviews: number;

        sumPageviews = 0;

        let tradicionalPorcentagem = 100 / parseInt(tradicionalValue);
        let ampPorcentagem = 100 / parseInt(ampValue);

        // se não tiver sites o valor recebe 0
        if(sites.length === 0) {
            setTotalValue('');
        }

        // forEach array para saber quais formatos foram selecionados
        formatosArray.forEach((formato) => {
            if(formato === 'intercontent') {
                setIntercontentFormat(true);
            }

            if(formato === 'alert') {
                setAlertFormat(true);
            }

            if(formato === 'preparagraph') {
                setPreparagraphFormat(true);
            }

            if(formato === 'footer') {
                setFooterFormat(true);
            }

            if(formato === 'sticky') {
                setStickyFormat(true);
            }

            if(formato === 'flying') {
                setFlyingFormat(true);
            }
        })

        // Executando um loop para atribuir o valor da soma das pageviews
        for(let i = 0; i < sites.length; i++) {
            sumPageviews += sites[i].pageviews;
        }

        // Atribui 0 aos valores dos calculos e dependendo dos formatos escolhidos, o calculo certo é atribuído e somado no final
        if(!intercontentFormat) {
            intercontentCalculo = 0;
        } else {
            intercontentCalculo = (((sumPageviews / tradicionalPorcentagem) / (100 / intercontentFillRate)) / 1000) * eCpmIntercontent / revenueShare;
        }

        if(!alertFormat) {
            alertCalculo = 0;
        } else {
            alertCalculo = (((sumPageviews / tradicionalPorcentagem) / (100 / alertFillRate)) / 1000) * eCpmAlert / revenueShare;
        }

        if(!preparagraphFormat) {
            preParagraphCalculo = 0;
        } else {
            preParagraphCalculo = (((sumPageviews / tradicionalPorcentagem) / (100 / preParagraphFillRate)) / 1000) * eCpmPreParagraph / revenueShare;
        }
        
        if(!footerFormat) {
            footerCalculo = 0;
        } else {
            footerCalculo = (((sumPageviews / tradicionalPorcentagem) / (100 / footerFillRate)) / 1000) * eCpmFooter / revenueShare;
        }

        if(!stickyFormat) {
            stickyCalculo = 0;
        } else {
            stickyCalculo = (((sumPageviews / ampPorcentagem) / (100 / stickyFillRate)) / 1000) * eCpmSticky / revenueShare;
        }

        if(!flyingFormat) {
            flyingCalculo = 0;
        } else {
            flyingCalculo = (((sumPageviews / ampPorcentagem) / (100 / flyingFillRate)) / 1000) * eCpmFlying / revenueShare;
        }

        // Somando os calculos (somente os que correspondem ao formato selecionado)
        setTotalValue(intercontentCalculo + alertCalculo + preParagraphCalculo + footerCalculo + stickyCalculo + flyingCalculo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sites, tradicionalValue]);

    // Hook para verificar se algum dos dados do Form de edição foi alterado para habilitar o botão de editar
    useEffect(() => {
        for(let i = 0; i < sites.length; i++) {
            let obj = sites[i];

            if(obj['id'] === currentIdEditing) {
                if(obj['site_name'] !== currentNameEditing || obj['dominio'] !== currentDominioEditing || obj['categoria'] !== currentCategoriaEditing || obj.pageviews.toLocaleString('pt-br') !== currentPageviewsEditing.toLocaleString('pt-br') || (editLogoUrl) || obj['bgColor'] !== currentColorTopbarEditing || obj['fontColor'] !== currentColorTextEditing) {
                    setEditButtonDisabled(false);
                } else {
                    setEditButtonDisabled(true);
                } 
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNameEditing, currentDominioEditing, currentCategoriaEditing, currentPageviewsEditing, currentLogoEditing, currentColorTopbarEditing, currentColorTextEditing, editLogoUrl]);

    // Hook que realiza uma requisição tipo POST à API, toda vez que o usuário, ADICIONA, EDITA ou EXCLUI um site, para que o valor de Sites no Banco de dados atualize sempre que houver uma alteração.
    useLayoutEffect(() => {
        if(totalValue) {
            api.post(`update-value/${token}`, {
                total_value: totalValue,
            }).then((res) => {
                // const data = res.data;
            });
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalValue]);

    // Função executada ao clicar no botão AVANÇAR
    function handleSubmit () {
        setLoadingData(true);

        setTimeout(() => {
            if(sites.length > 0) {
                // Se tiver algum objeto dentro do array de Sites, vem para aqui
                setLoadingData(false);
                navigate('/cadastro-admanager');
            } else {
                // Se o array de Sites estiver vazio, vem para aqui
                setLoadingData(false);
                setOpenErrorMessage(true);
                setErrorMessage('Você precisa adicionar ao menos um site para ir para a próxima página');
            }
        }, 1000)
    }

    // Função que adiciona um novo site
    function addNewSite() {
        setLoadingSite(true);
        let bloco: string;

        // Verifica se o domínio foi preenchido, se sim, cria um 'bloco' que é gerado a partir do domínio preenchido
        if(siteDominio) {
            bloco = createBlocoFromDominio(siteDominio);
        }

        const formData = new FormData() 
        formData.append('name', siteName);
        formData.append('dominio', siteDominio);
        formData.append('bloco', bloco);
        formData.append('categoria', siteCategoria);
        formData.append('pageviews', sitePageviews.toString());
        formData.append('logo', logoUrl);
        formData.append('logoFile', logoFile);
        formData.append('bgColor', siteColorTopbar);
        formData.append('fontColor', siteColorText);

        /* 
            Após um timeout de 1s, uma verificação é feita para saber se tudo está correto, se sim a inserção será feita, se não, retorna uma mensagem de erro, dependendo do que está errado. 
            Caso esteja certo, logo após a inserção, os valores são limpados para que o usuário possa preencher novamente logo em seguida 
        */
        if(siteName && bloco && logoUrl && !errorImage && siteDominio && siteCategoria && sitePageviews && siteColorTopbar && siteColorText) {
            // Se tiver algum objeto dentro do array de Sites, vem para aqui
            api.post(`add-site/${token}`, formData).then((response) => {
                api.get(`lastid/${token}`).then((res) => {
                    const data = res.data;
                    const sitedata = response.data;

                    // sitedata.result -> Retorna a imagem cadastrada redimensionada do backend
                    setSites([
                        ...sites,
                        {
                            id: data,
                            site_name: siteName,
                            bloco: bloco,
                            dominio: siteDominio,
                            categoria: siteCategoria,
                            pageviews: sitePageviews,
                            logo: sitedata.result,
                            logourl: sitedata.result,
                            bgColor: siteColorTopbar,
                            fontColor: siteColorText,
                            adstxt: 'warning',
                            mcm: 0,
                        }
                    ]);

                    setSiteName('');
                    setSiteDominio('');
                    setSiteCategoria('');
                    setSitePageviews(0);
                    setLogoUrl('');
                    setSiteColorTopbar('#FFB300');
                    setSiteColorText('#454849');
        
                    setOpenSuccessMessage(true);
                    setOpenErrorMessage(false);
                    setSuccessMessage('Novo site adicionado com sucesso');
                    setShowForm(false);
                    
                    setLoadingSite(false);
                })
            })
        } else {
            setTimeout(() => {

                setOpenSuccessMessage(false);
                setOpenErrorMessage(true);
    
                if(!siteName) {
                    setHasSiteName(false);
                    setErrorMessage('Preencha todos os dados do formulário');
                } else {
                    setHasSiteName(true);
                }

                if(errorImage || !logoUrl) {
                    setHasSiteLogo(false)
                    setErrorMessage('Algo deu errado com a imagem, verique o tamanho e o tipo (png, jpg)');
                } else if (!logoFile) {
                    setHasSiteLogo(false);
                    setErrorMessage('Preencha todos os dados do formulário');
                } else {
                    setHasSiteLogo(true);
                }
    
                if(!siteDominio) {
                    setHasSiteDominio(false);
                    setErrorMessage('Preencha todos os dados do formulário');
                } else {
                    if(!bloco) {
                        setHasSiteDominio(false);
                        setErrorMessage('Url inválida, tente novamente');
                    } else {
                        setHasSiteDominio(true);
                    } 
                }
    
                if(!siteCategoria) {
                    setHasSiteCategoria(false);
                    setErrorMessage('Preencha todos os dados do formulário');
                } else {
                    setHasSiteCategoria(true);
                }
    
                if(!sitePageviews) {
                    setHasSitePageviews(false);
                    setErrorMessage('Preencha todos os dados do formulário');
                } else {
                    setHasSitePageviews(true);
                }

                setLoadingSite(false);
            }, 1000);
        }
    }

    // Verificando valor das pageviews que não pode ter mais de 9 caracteres (numeros)
    function handlePageviewValue() {
        /* 
            Explicando o que foi feito porque o TypeScript acusava erro, basicamente tive que passar a pageview para String, depois fazer a divisão dessa string para não passasr de 10 caracteres,
            e depois passando de volta a string para INTEGER e setando na variável Pageviews
        */

        let pageviewToString = sitePageviews.toString();

        if(sitePageviews > 9999999999) {
            let pageviewsToInt = pageviewToString.substr(0, 10);
            setSitePageviews(parseInt(pageviewsToInt));
        } else if (sitePageviews < 0) {
            setSitePageviews(0);
        } else {
            setSitePageviews(Math.round(sitePageviews))
        }
    }

    // Função que abre o modal de apagar o site
    function handleOpenModal(id: any, name: string, bloco: string, dominio: string, categoria: string, pageviews: number, logo: string, colorTopbar: string, colorText: string, index: any) {
        setOpenModal(true);

        setCurrentIdEditing(id);
        setCurrentNameEditing(name);
        setCurrentBlocoEditiging(bloco);
        setCurrentDominioEditing(dominio);
        setCurrentCategoriaEditing(categoria);
        setCurrentPageviewsEditing(pageviews);
        setCurrentLogoEditing(logo);
        setCurrentColorTopbarEditing(colorTopbar);
        setCurrentColorTextEditing(colorText);

        setDeleteSiteIndex(index);
    }

    // Função que deleta um dos sites adicionados
    function deleteSite() {
        setLoadingDeleteData(true);

        setTimeout(() => {
            setLoadingDeleteData(false);

            let filterSitesArray = sites.filter((item, k) => k !== parseInt(deleteSiteIndex));
            setSites(filterSitesArray);

            setOpenDeleteModal(false);
            setOpenSuccessMessage(true);
            setSuccessMessage('Site deletado com sucesso');
        }, 1000);


        api.post(`delete-site/${currentIdEditing}`).then((res) => {
            // const data = res.data;
        });
    }

    // Função que edita dados do site selecionado
    function editSite() {
        setLoadingEditingData(true);

        const bloco = createBlocoFromDominio(currentDominioEditing);

        const formData = new FormData() 
        formData.append('name', currentNameEditing);
        formData.append('bloco', bloco);
        formData.append('dominio', currentDominioEditing);
        formData.append('categoria', currentCategoriaEditing);
        formData.append('pageviews', currentPageviewsEditing.toString());
        formData.append('logo', currentLogoEditing);
        formData.append('logoFile', currentLogoFileEditing);
        formData.append('bgColor', currentColorTopbarEditing);
        formData.append('fontColor', currentColorTextEditing);

        api.post(`update-site/${currentIdEditing}`, formData).then((res) => {
            setLoadingEditingData(false);

            const data = res.data;

            if(data.result) {
                for(let i = 0; i < sites.length; i++) {
                    let obj = sites[i];
        
                    if(obj.id === currentIdEditing) {
                        if(obj.site_name !== currentNameEditing) {
                            obj.site_name = currentNameEditing;
        
                            if(!obj.logo) {
                                let name_split = obj.site_name.split(' ');
            
                                if(name_split.length > 1) {
                                    setSiteSigla(name_split[0][0] + name_split[1][0]);
                                } else {
                                    setSiteSigla(name_split[0][0] + name_split[0][1]);
                                }
                            }
                        }

                        if(obj.dominio !== currentDominioEditing) {
                            obj.dominio = currentDominioEditing;
                            obj.bloco = createBlocoFromDominio(currentDominioEditing);
                        }
            
                        if(obj.categoria !== currentCategoriaEditing) {
                            obj.categoria = currentCategoriaEditing;
                        }
            
                        if(obj.pageviews !== currentPageviewsEditing) {
                            if(currentPageviewsEditing > 9999999999) {
                                let toString = currentPageviewsEditing.toString();
                                let toInt = toString.substr(0, 10);
                                obj.pageviews = parseInt(toInt);
                            } else if (currentPageviewsEditing < 0) {
                                obj.pageviews = 0;
                            } else {
                                obj.pageviews = Math.round(currentPageviewsEditing);
                            }
                        }
        
                        if(obj.logo !== currentLogoEditing) {
                            obj.logo = currentLogoEditing;
                        }
        
                        if(obj.bgColor !== currentColorTopbarEditing) {
                            obj.bgColor = currentColorTopbarEditing;
                        }
        
                        if(obj.fontColor !== currentColorTextEditing) {
                            obj.fontColor = currentColorTextEditing;
                        }
                    }
                }
                setOpenSuccessMessage(true);
                setSuccessMessage('Dados editados com sucesso');
            } else {
                setOpenErrorMessage(true);
                setErrorMessage(data.error);
            }

            setCurrentIdEditing('');
            setEditLogoUrl('');
            setCurrentLogoEditing('');
            setCurrentLogoFileEditing('');

            setOpenModal(false);
        }).catch((err) => {
            setLoadingEditingData(false);
        })
    }

    // Upload de imagem no cadastro de um novo site
    function setImage(e: any){
        let _URL = window.URL || window.webkitURL;

        let imagem = e.files[0]
        let reader = new FileReader();

        setLogoFile(imagem);

        let file: any, img: any;

        file = imagem;

        img = new Image();

        img.onload = function() {

            img.onerror = function() {
                setErrorImage(true);
            };

            // if(this.width > 110 || this.height > 32) {
            //     setErrorImage(true);
            // } else {
            //     setErrorImage(false);

                reader.onloadend = function () {
                    Resizer.imageFileResizer(
                        imagem,
                        100,
                        32,
                        "png",
                        100,
                        0,
                        (uri: any) => {
                            setLogoUrl(uri);
                        },
                        "base64",
                        100, 
                        32
                    );
                }
        
                if( imagem ){
                    reader.readAsDataURL(imagem)
                }
            // }
        };

        img.src = _URL.createObjectURL(file);
    }

    // Upload de imagem na edição de um site
    function editImage(e: any){
        let _URL = window.URL || window.webkitURL;

        let imagem = e.files[0]
        let reader = new FileReader();

        setCurrentLogoFileEditing(imagem);

        let file: any, img: any;

        file = imagem;

        img = new Image();

        img.onload = function() {

            img.onerror = function() {
                setEditErrorImage(true);
            };

            // if(this.width > 110 || this.height > 32) {
            //     setEditErrorImage(true);
            // } else {
                setEditErrorImage(false);

                reader.onloadend = function () {
                    Resizer.imageFileResizer(
                        imagem,
                        100,
                        32,
                        "png",
                        100,
                        0,
                        (uri: any) => {
                            setEditLogoUrl(uri);
                            setCurrentLogoEditing(uri);
                        },
                        "base64",
                        100, 
                        32
                    );
                }
        
                if( imagem ){
                    reader.readAsDataURL(imagem)
                }
            // }
        };

        img.src = _URL.createObjectURL(file);
    }

    // Component EXEMPLOS
    const ExamplesComponent = () => (
        <div className={styles.examples}>
            {formatosArray && formatosArray !== null ?
                <div className={styles.exampleContainer}>
                    {formatosArray.map((formato) => (
                        <div key={formato}>
                            {formato === 'intercontent' ? 
                                <div className={styles.exampleItem}>
                                    <span>Intercontent</span>
                                    <img alt="Intercontent" src={IconIntercontentImg} />
                                </div>
                                : null
                            }

                            {formato === 'alert' ? 
                                <div className={styles.exampleItem}>
                                    <span>Alert</span>
                                    <img alt="alert" src={IconAlertImg} />
                                </div>
                                : null
                            }

                            {formato === 'preparagraph' ? 
                                <div className={styles.exampleItem}>
                                    <span>Pre Paragraph</span>
                                    <img alt="preparagraph" src={ExamplePreParagraphImg} />
                                </div>
                                : null
                            }

                            {formato === 'footer' ? 
                                <div className={styles.exampleItem}>
                                    <span>Footer</span>
                                    <img alt="footer" src={ExampleFooterImg} />
                                </div>
                                : null
                            }

                            {formato === 'sticky' ? 
                                <div className={styles.exampleItem}>
                                    <span>Sticky</span>
                                    <img alt="sticky" src={ExampleStickyImg} />
                                </div>
                                : null
                            }

                            {formato === 'flying' ? 
                                <div className={styles.exampleItem}>
                                    <span>Flying Carpet</span>
                                    <img alt="flying" src={ExampleFlyingCarpetImg} />
                                </div>
                                : null
                            }
                        </div>
                    ))}
                </div>
            :
                <span className={styles.notFormatos}>Ocorreu um erro ao carregar os formatos</span>
            }

        </div>
    )

    // Component FORMATOS       
    const FormatoPreview = () => (
        <div className={styles.previewArea}>
            <div className={styles.preview}>
                <div style={{backgroundColor: siteColorTopbar }} className={styles.topBar}>
                    {logoUrl && !errorImage ?
                        <img alt="logo-in-preview" src={logoUrl} />
                    :
                        <div className={styles.logoPlaceholder}></div>
                    }

                    <div className={styles.right}>
                        <span style={{color: siteColorText}}>Pular propaganda</span>
                        <div style={{backgroundColor: siteColorText}} className={styles.colorText}></div>
                    </div>
                </div>
            </div>
        </div>
    )

    // Component SITE 
    const SitePreview = () => (
        <div className={styles.previewSites}>
            <h2>Meus sites</h2>
            
            <Button onClick={() => setShowForm(!showForm)} style={{height: '2.3rem', color: 'var(--black)'}} appearance="primary">Cadastrar novo site</Button>

            {sites.length > 0 ?
                <div className={styles.inlineSites}>
                    {sites.map((item, k) => (
                        <Whisper key={k} placement="topStart" controlId="control-id-hover" trigger="hover" speaker={tooltip}>
                            <div
                                onMouseOver={() => {
                                    setCurrentHoverSiteName(item.site_name);
                                    setCurrentHoverSiteDominio(item.dominio);
                                    setCurrentHoverSitePageviews(item.pageviews);
                                }}
                                onClick={() => handleOpenModal(item.id, item.site_name, item.bloco, item.dominio, item.categoria, item.pageviews, item.logo, item.bgColor, item.fontColor, k)}
                                className={styles.previewItem}
                            >
                                <div className={styles.logoContainer}>
                                    <div style={{backgroundColor: item.bgColor}} className={styles.logoArea}>
                                        {item.logo ?
                                            <div className={styles.iconLogo}>
                                                <img alt="logo-site" src={item.logo}/>
                                            </div>
                                            :
                                            <span>{siteSigla}</span>
                                        }
                                    </div>
                                </div>

                                <div className={styles.nameWrapper}>
                                    <span className={styles.name}>{item.bloco}</span>
                                </div>
                            </div>

                        </Whisper>
                    ))}
                </div>
                :
                null
            }
        </div>
    )

    // Componente de confirmação (valor total e botão avançar)
    const Confirmation = () => (
        <div className={styles.confirmationArea}>
            <div className={styles.alignLeft}>
                <span>Receita estimada total</span>
                <div className={styles.inlineValue}>
                    <span>{parseInt(totalValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}</span>
                    <span>por mês</span>
                </div>
            </div>

            <div className={styles.buttonArea}>
                <Button disabled={disabledButton ? disabledButton : loadingData} onClick={handleSubmit} style={{width: '8rem', height: '2.3rem', color: 'var(--black)'}} appearance="primary">{loadingData ? <Loader size={"sm"} /> : 'Avançar'}</Button>
            </div>
        </div>
    )

    // Component do RSUITE que é um tooltip (uma pequena mensagem que aparece ao passar o mouse por cima do SitePreview)
    const tooltip = (
        <Tooltip style={{display: 'flex', flexDirection: 'column'}}>
          <span><strong>Nome:</strong> {currentHoverSiteName}</span>
          <span><strong>Dominio:</strong> {currentHoverSiteDominio}</span>
          <span><strong>Pageviews:</strong> {currentHoverSitePageviews.toLocaleString('pt-br')}</span>
        </Tooltip>
    );

    return(
        <div className={styles.container}>
            <Loading load />

            <div style={{opacity: loading ? 0 : 1, transition: '500ms'}}>
                <HeaderText title="Cadastro de sites">
                 Neste passo, você pode cadastrar um ou mais sites, define as cores e o logotipo para ser usado na configuração de alguns formatos. Use as cores dos seus sites para combinar com o layout e dar um aspecto mais harmonioso com os anúncios, observe o preview e o contraste entre o fundo com o logo e o texto.
                 Após este passo, vamos passar mais instruções, verificar os pré-requisitos e habilitar a entrega dos anúncios programática em seus domínios.
                </HeaderText>

                <CallbackMessage successMessage={successMessage} openSuccessMessage={openSuccessMessage} setOpenSuccessMessage={setOpenSuccessMessage} errorMessage={''} openErrorMessage={false} setOpenErrorMessage={false} />
                <CallbackMessage errorMessage={errorMessage} openErrorMessage={openErrorMessage} setOpenErrorMessage={setOpenErrorMessage} successMessage={''} openSuccessMessage={false} setOpenSuccessMessage={false} />

                {/* Modal para editar sites */}
                <Modal open={openModal} onClose={() => {
                    setOpenModal(false);
                    setEditLogoUrl('');
                }}>
                   <Modal.Header>
                        <Modal.Title style={{fontWeight: 'bold', fontSize: '1.5rem'}}>Editar site</Modal.Title>
                   </Modal.Header>

                   <Modal.Body>
                        <FormEditSite
                            currentNameEditing={currentNameEditing} setCurrentNameEditing={setCurrentNameEditing} 
                            currentDominioEditing={currentDominioEditing} setCurrentDominioEditing={setCurrentDominioEditing} 
                            currentCategoriaEditing={currentCategoriaEditing} setCurrentCategoriaEditing={setCurrentCategoriaEditing} 
                            currentPageviewsEditing={currentPageviewsEditing} setCurrentPageviewsEditing={setCurrentPageviewsEditing} 
                            currentColorTopbarEditing={currentColorTopbarEditing} setCurrentColorTopbarEditing={setCurrentColorTopbarEditing}
                            currentColorTextEditing={currentColorTextEditing} setCurrentColorTextEditing={setCurrentColorTextEditing}
                            editImage={editImage}
                            editLogoUrl={editLogoUrl} currentLogoEditing={currentLogoEditing} editErrorImage={editErrorImage}
                        />
                   </Modal.Body>

                   <Modal.Footer>
                        <Button style={{width: '6rem', height: '2.3rem', color: 'var(--black)'}} disabled={editButtonDisabled ? editButtonDisabled : false} onClick={editSite} appearance="primary">{loadingEditingData ? <Loader /> : 'Salvar'}</Button>
                        <Button onClick={() => {
                            setOpenDeleteModal(true);
                            setOpenModal(false);
                        }} color="red" appearance="primary">
                            <TrashIcon color="#fff" style={{width: '1rem', height: '1rem'}} />
                        </Button>
                   </Modal.Footer>
                </Modal> 

                <Modal open={showForm} onClose={() => {
                    setShowForm(false);
                    setLogoUrl('');
                }}>
                   <Modal.Header>
                        <Modal.Title style={{fontWeight: 'bold', fontSize: '1.5rem'}}>Cadastrar novo site</Modal.Title>
                   </Modal.Header>

                   <Modal.Body>
                        <FormAddNewSite 
                            siteName={siteName} setSiteName={setSiteName} 
                            siteDominio={siteDominio} setSiteDominio={setSiteDominio} siteCategoria={siteCategoria} setSiteCategoria={setSiteCategoria} 
                            sitePageviews={sitePageviews} setSitePageviews={setSitePageviews}
                            logoUrl={logoUrl} errorImage={errorImage} setImage={setImage}
                            siteColorTopbar={siteColorTopbar} setSiteColorTopbar={setSiteColorTopbar} siteColorText={siteColorText} setSiteColorText={setSiteColorText}
                            loadingSite={loadingSite} handlePageviewValue={handlePageviewValue} addNewSite={addNewSite} 
                            hasSiteName={hasSiteName} hasSiteDominio={hasSiteDominio} hasSiteCategoria={hasSiteCategoria} hasSitePageviews={hasSitePageviews} hasSiteLogo={hasSiteLogo}
                        />
                   </Modal.Body>
                </Modal> 

                {/* Modal para deletar sites */}
                <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                    <Modal.Header>
                        <Modal.Title>Tem certeza que deseja excluir o site <strong>{currentBlocoEditing}</strong>?</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <span>Ao clicar em OK você irá apagar os dados deste site</span>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button disabled={loadingDeleteData} style={{width: '5rem', height: '2.3rem', color: 'var(--black)'}} onClick={deleteSite} appearance="primary">{loadingDeleteData ? <Loader /> : 'Ok'}</Button>
                        <Button onClick={() => setOpenDeleteModal(false)} appearance="subtle">Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <section className={styles.formArea}>
                    <div className={styles.examples}>
                        <h2>Formatos escolhidos</h2>

                        <ExamplesComponent />
                    </div>
                    
                    <div className={styles.inlinePreviewAndForm}>
                        <FormatoPreview />

                        <div className={styles.siteInfoArea}>
                            <SitePreview />
                        </div>
                    </div>
                </section>

                <Confirmation />
            </div>
        </div>
    )
}