import React, { useState, useEffect } from 'react';

import { Button, Loader } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';                                       
import Cookies from 'universal-cookie';  

import {ReactComponent as ImgNotFound} from '../../assets/svg/not-found.svg';

import { api } from '../../services/api';

import styles from './styles.module.scss';

export default function NotFound() {
    const cookies = new Cookies();
    const token = cookies.get('token-acesso');

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [svgValue, setSvgValue] = useState('500px')

    const isMobile = useMediaQuery({query: '(max-width: 450px)'});          // Largura de device no máximo 479px
    const isLargeMobile = useMediaQuery({query: '(max-width: 600px)'});          // Largura de device no máximo 479px

    useEffect(() => {
        if(isMobile) {
            setSvgValue('350px');
        } else if (isLargeMobile) {
            setSvgValue('450px');
        } else {
            setSvgValue('500px');
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    function goToHome() {
        setLoading(true);

        api.get(`user/${token}`).then((res) => {
            const data = res.data.result[0];

            setLoading(false);

            if(data.length > 0) {
                navigate('/preview-dos-formatos');
            } else {
                navigate('/');
            }
        })
    }

    return(
        <div className={styles.container}>
            <ImgNotFound width={svgValue} height={svgValue} />

            <h1>Página não encontrada</h1>

            <p>Oops... Não consiguimos encontrar a página que você procurou, verifique se o endereço digitado está correto, ou volte ao início clicando no botão abaixo.</p>
            <Button disabled={loading} onClick={goToHome} style={{width: '8rem', height: '2.3rem', marginTop: '1rem', color: 'var(--black)'}} appearance="primary">{loading ? <Loader size="sm" /> : 'Ir para início'}</Button>
        </div>
    )
}