/** ---------------- Variáveis de valores FillRate e eCPM  (Intercontent, Alert, PreParagraph, Footer, Sticky, Flying) ----------------  */
export const intercontentFillRate = 19.36;
export const eCpmIntercontent = 6.20;

export const alertFillRate = 50;
export const eCpmAlert = 1.35;

export const preParagraphFillRate = 40;
export const eCpmPreParagraph = 1.05;

export const footerFillRate = 77;
export const eCpmFooter = 0.90;

export const stickyFillRate = 50;
export const eCpmSticky = 1.50;

export const flyingFillRate = 20;
export const eCpmFlying = 2;

// Taxa de repartição de valor (meio a meio)
export const revenueShare = 2;
