import React from 'react';
import { Snackbar, Alert } from '@mui/material';   

export default function CallbackMessage ({ successMessage, openSuccessMessage, setOpenSuccessMessage, errorMessage, openErrorMessage, setOpenErrorMessage }) {
    return(
        <Snackbar 
            autoHideDuration={4000} 
            anchorOrigin={{vertical: 'top', horizontal: 'center'}} 
            onClose={openSuccessMessage ? () => setOpenSuccessMessage(false) : () => setOpenErrorMessage(false)} 
            open={openSuccessMessage ? openSuccessMessage : openErrorMessage}
        >
            <Alert onClose={openSuccessMessage ? () => setOpenSuccessMessage(false) : () => setOpenErrorMessage(false)} severity={openSuccessMessage ? 'success' : 'error'} sx={{ width: '100%' }}>
                {openSuccessMessage ? successMessage : errorMessage}
            </Alert>
        </Snackbar>
    )
}