import React, { useState, useEffect } from 'react';

import CheckRoundIcon from '@rsuite/icons/CheckRound';
import Cookies from 'universal-cookie';               

import { api } from '../../services/api';

import styles from './thank_you.module.scss';

export default function ThankYou() {
    const cookies = new Cookies();          
    const token = cookies.get('token-acesso');// Variável para armazenar e setar cookies

    const [email, setEmail] = useState('');             // Variável que armazena o email do usuário
    const [phone, setPhone] = useState('');             // Variável que armaazena o telefone do usuário
    const [loading, setLoading] = useState(true);       // Variável que da um efeito de loading nos dados

    // Hook para pegar os dados do usuário ao carregar a tela
    useEffect(() => {
        api.get(`user/${token}`).then((res) => {
            const data = res.data.result[0];

            setEmail(data.email);
            setPhone(data.phone);
            setLoading(false);
        }).catch((err) => {
            console.error(err);
        })
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <div className={styles.container}>  
            <div style={{transform: loading ? 'translateY(-20rem)' : 'translateY(0)', opacity: loading ? 0 : 1}} className={styles.innerContainer}>
                <div className={styles.icon}>
                    <CheckRoundIcon style={{width: '7rem', height: '7rem'}} color="var(--green)" />
                </div>

                <h1>Obrigado</h1>

                <p>Seu cadastro está feito, agora entraremos em contato com você via <strong>{email}</strong> ou <strong>{phone}</strong></p>
            </div>
        </div>
    )
}