import React, { createContext, useState } from 'react';
import Cookies from "universal-cookie";

type SiteInfoData = {
    currentStepContext: number;
    currentToken: string;
    showButton: boolean;
    formatosSto: string;
    sitesSto: string;
    totalValueSto: string;
    tradicionalValueSto: string;
    ampValueSto: string;
    
    saveCurrentStep: (step: number) => void;
    saveCurrentToken: (token: string) => void;
    saveButtonState: (state: boolean) => void;
    saveData: (forma: string, sit: string, tot: number, tra: number, amp: number) => void;
};

export const SiteInfoContext = createContext({} as SiteInfoData);           // Criando Context

export function SiteInfoContextProvider({ children }) {
    const [currentStepContext, setCurrentStep] = useState(0);    // Variável que armazena o passo atual
    const [currentToken, setCurrentToken] = useState(localStorage.getItem('token'));
    const [showButton, setShowButton] = useState(false);

    const [formatosSto, setFormatos] = useState(localStorage.getItem('formatos'));
    const [sitesSto, setSites] = useState(localStorage.getItem('sites'));
    const [totalValueSto, setTotalValue] = useState(localStorage.getItem('total_value'));
    const [tradicionalValueSto, setTradicionalValue] = useState(localStorage.getItem('tradicional'));
    const [ampValueSto, setAmpValue] = useState(localStorage.getItem('amp'));

    const cookies = new Cookies();

    // Funçãp que salva o valor do passo atual
    function saveCurrentStep (step: number) {
        setCurrentStep(step);
    }

    function saveCurrentToken(token: string) {
        setCurrentToken(token);
        localStorage.setItem('token', token);

        cookies.set('token-acesso', token, {
            maxAge: 10000000
        });
    }

    function saveButtonState(state: boolean) {
        setShowButton(state);
    }

    function saveData(forma: string, sit: string, tot: number, tra: number, amp: number) {
        localStorage.setItem('formatos', forma);
        localStorage.setItem('sites', sit);
        localStorage.setItem('total_value', tot.toString());
        localStorage.setItem('tradicional', tra.toString());
        localStorage.setItem('amp', amp.toString());

        setFormatos(forma);
        setSites(sit);
        setTotalValue(tot.toString());
        setTradicionalValue(tra.toString());
        setAmpValue(amp.toString());
    }

    return (
        <SiteInfoContext.Provider value={{ 
            currentStepContext,
            saveCurrentStep,
            currentToken,
            saveCurrentToken,
            showButton,
            saveButtonState,
            formatosSto,
            sitesSto,
            totalValueSto,
            tradicionalValueSto,
            ampValueSto,
            saveData
        }}>
            {children}
        </SiteInfoContext.Provider>
    )
}