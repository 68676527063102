import React, { useState, useEffect, useContext } from 'react';

/** --------------------- IMPORT DE BIBLIOTECAS --------------------- */
import { Button, Loader, Whisper, Tooltip } from 'rsuite';                                      
import CheckRoundIcon from '@rsuite/icons/CheckRound';                       
import RemindFillIcon from '@rsuite/icons/RemindFill';    
import WarningRoundIcon from '@rsuite/icons/WarningRound';                   
import { useNavigate } from 'react-router-dom';                                    
import validator from 'validator';   
import Cookies from 'universal-cookie';
/** --------------------- --------------------- --------------------- **/


/** --------------------- IMPORT DE FUNÇÕES/CONEXÕES PARA SALVAR DADOS --------------------- */
import { api } from '../../services/api';       
import { SiteInfoContext } from '../../contexts/SiteInfo';                               
/** --------------------- --------------------- --------------------- --------------------- **/


/** --------------------- IMPORT DE HELPERS --------------------- */
import HeaderText from '../../helpers/HeaderText';                          
import CallbackMessage from '../../helpers/CallbackMessage';
/** --------------------- --------------------- ---------------- **/

import { FormAdmanager } from '../../Forms';

// Component Loading
import Loading from '../../helpers/Loading';

// Estilização
import styles from './approval.module.scss';                                

export default function Approval() {
    const navigate = useNavigate();

    // Criação de Cookies para o navegador
    const cookies = new Cookies();    
    const token = cookies.get('token-acesso');
    
    const { saveCurrentStep } = useContext(SiteInfoContext);

    /** --------------------------- Variáveis que o usuário precisa preencher --------------------------- */
    const [idAdmanager, setIdAdmanager] = useState('');
    const [email, setEmail] = useState('');
    /** -------------------------- -------------------------- ------------------------------------------ **/


    const [sites, setSites] = useState([]);
    const [hasIdFromDB, setHasIdFromDB] = useState(false);
    const [hasEmailFromDB, setHasEmailFromDB] = useState(false);
    const [adstxtError, setAdstxtError] = useState();
    const [mcmError, setMcmError] = useState();


    /** --------------------------- Verificação se variáveis foram preenchidas corretamente --------------------------- */
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [hasId, setHasId] = useState(true);
    const [hasEmail, setHasEmail] = useState(true);
    /** -------------------------- -------------------------- ---------------------------------- ---------------------- **/


    /** --------------------------- Atribuir e abrir mensagem de erro --------------------------- */
    const [openErrorMessage, setOpenErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('')
    /** -------------------------- -------------------------- ----------------------------------- **/


    /** ----------------------------------- Loading de visualização ----------------------------------- */
    const [loading, setLoading] = useState(true);
    // const [loadingData, setLoadingData] = useState(false);
    const [loadingMCM, setLoadingMCM] = useState(false);
    /** ----------------------------------- ----------------------------------- ----------------------- **/

    // Para o loading depois de 1.5s 
    useEffect(() => {
        saveCurrentStep(2);

        api.get(`user/${token}`).then((res) => {
            const datasite = res.data.result[0];
            const datauser = res.data.result[1];

            if(datasite.length === 0) {
                navigate('/preview-dos-formatos');
                saveCurrentStep(1);
            }

            if(datauser.id_admanager && datauser.email_admanager) {
                setIdAdmanager(datauser.id_admanager);
                setEmail(datauser.email_admanager); 

                setHasIdFromDB(true);
                setHasEmailFromDB(true);
            }

            setSites(datasite);
            
            setTimeout(() => {
                setLoading(false);
            }, 1500)
        })

     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [])

    // Verifica se o email está preenchido e se é válido, ao mudar seu valor
    useEffect(() => {
        if (email) {
            setHasEmail(true);

            let isEmail = validator.isEmail(email);
    
            if(isEmail) {
                setIsValidEmail(true);
            }  else {
                setIsValidEmail(false);
            }
        } 
    }, [email]);

    // Verifica se o id do Admanager está preenchido, ao mudar seu valor
    useEffect(() => {
        if(idAdmanager) {
            setHasId(true);
        }
    }, [idAdmanager]);

    // Ao clicar em avançar. Caso algo esteja incorreto, irá passar pela fase de verificação que retornará uma mensagem com o erro. Caso esteja tudo OK, irá avançar para a página final.
    function handleSubmit() {
        setLoadingMCM(true);
        
        if(idAdmanager && email && isValidEmail) {
            api.post(`admanager-info/${token}`, {
                id_admanager: idAdmanager,
                email_admanager: email
            }).then((res) => {
                const data = res.data;
                
                if(data.result) {
                    setLoadingMCM(false);
                    setHasIdFromDB(true);
                    setHasEmailFromDB(true);
                    setOpenSuccessMessage(true);
                    setSuccessMessage(data.result);
                } else {
                    setLoadingMCM(false);
                    setErrorMessage(data.error);
                    setOpenErrorMessage(true);
                }
            })
        } else {
            setTimeout(() => {
                setOpenErrorMessage(true);
            
                if(!idAdmanager) {
                    setHasId(false);
                } else {
                    setHasId(true);
                }
        
                if(!email) {
                    setHasEmail(false);
                    setIsValidEmail(false);
                    setErrorMessage('Todos os campos são obrigatórios');
                } else {
                    let isEmail = validator.isEmail(email);
        
                    if(isEmail) {
                        setHasEmail(true);
                        setIsValidEmail(true);
                    }  else {
                        setIsValidEmail(false);
                        setHasEmail(false);
                        setErrorMessage('Email inválido');
                    }
                }

                setLoadingMCM(false);
            }, 1000)
        }
    }

    // function goToNext() {
    //     setLoadingData(true);
        
    //     setTimeout(() => {
    //         setLoadingData(false);
    //         navigate('/cadastro-painel-nobeta');
    //     }, 1000)
    // };

    const SitesArea = () => (
        <section>
            <div className={styles.adManagerArea}>
                <h2>ADS.TXT e MCM</h2>

                <p>
                    O ads.txt é um arquivo acessível a partir da raiz dos seus sites, para começar a entrega de anúncios programático, é necessário criar ou atualizar este arquivo com as linhas da nobeta. Abaixo está o status do nosso monitoramento quanto a atualização do arquivo ads.txt e a aprovação ao MCM, após o preenchimento dos dados do ID do AdManager e Email de cadastro AdSense/AdManager.
                </p>
                <p>
                    Fazemos verificações diária, então o status é atualizado a cada 24 horas, mas não se preocupe, enviaremos um email assim que estes requisitos forem atendidos!
                </p>

                <div className={styles.verifySiteArea}>
                    {sites.map((item, k) => (
                        <div key={k} className={styles.siteItem}>
                            <span>{item.site_name && item.site_name !== 'null' ? `${item.site_name} - ` : ''} {`https://${item.dominio}`}</span>

                            <div className={styles.inlineResponse}>
                                <Whisper key={item.id} placement="topStart" controlId="control-id-hover" trigger="hover" speaker={tooltipAdstxt}>
                                    <div
                                        onMouseOver={() => {
                                            setAdstxtError(item.adstxtstatus);
                                            setMcmError(item.mcmstatus);
                                        }} 
                                        className={styles.verifyResponse}
                                    >
                                        <div className={styles.situation}>
                                            {item.adstxtlevel === 'warning' ? 
                                                <RemindFillIcon style={{width: '1.5rem', height: '1.5rem'}} color="orange" />
                                            : 
                                            item.adstxtlevel === 'notok' ?
                                                <WarningRoundIcon style={{width: '1.5rem', height: '1.5rem'}} color="red" />
                                            :
                                            item.adstxtlevel === 'ok' ?
                                                <CheckRoundIcon style={{width: '1.5rem', height: '1.5rem'}} color="green" />
                                            : 
                                            null}
                                        </div>

                                        <div className={styles.title}>
                                            <h2>ADS.TXT</h2>
                                        </div>
                                    </div>
                                </Whisper>

                                <Whisper key={k} placement="topStart" controlId="control-id-hover" trigger="hover" speaker={tooltipMcm}>
                                    <div className={styles.verifyResponse}>
                                        <div className={styles.situation}>
                                            {item.mcmlevel === 0 || item.mcmlevel === '' ? 
                                                <RemindFillIcon style={{width: '1.5rem', height: '1.5rem'}} color="orange" />
                                            : 
                                            item.mcmlevel === 'notok' ?
                                                <WarningRoundIcon style={{width: '1.5rem', height: '1.5rem'}} color="red" />
                                            :
                                            item.mcmlevel === 'ok' ?
                                                <CheckRoundIcon style={{width: '1.5rem', height: '1.5rem'}} color="green" />
                                            : 
                                            null}
                                        </div>

                                        <div className={styles.title}>
                                            <h2>MCM</h2>
                                        </div>
                                    </div>
                                </Whisper>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </section>
    )

    const tooltipAdstxt = (
        <Tooltip style={{display: 'flex', flexDirection: 'column'}}>
          <span>{adstxtError}</span>
        </Tooltip>
    );

    const tooltipMcm = (
        <Tooltip style={{display: 'flex', flexDirection: 'column'}}>
          <span>{mcmError}</span>
        </Tooltip>
    );

    return(
        <div className={styles.container}>
            <Loading load />

            <div style={{opacity: loading ? 0 : 1, transition: '500ms'}}>

                <CallbackMessage errorMessage={errorMessage} openErrorMessage={openErrorMessage} setOpenErrorMessage={setOpenErrorMessage} successMessage={successMessage} openSuccessMessage={openSuccessMessage} setOpenSuccessMessage={setOpenSuccessMessage} />

                <HeaderText children="" title="Estamos quase lá!" />

                <div className={styles.contentArea}>
                    <section>
                        <div className={styles.confirmData}>
                            <h2>Criação de conta no Google Admanager</h2>

                            <p>
                                Existe uma exigência do Google AdManager para entregar anúncios programática , que é uma aprovação do conteúdo dos seus sites, de acordo com a <a href="https://support.google.com/admanager/topic/7402024?hl=pt-BR&ref_topic=7316904" rel="noreferrer" target="_blank">política de programa definida por eles</a>. Para pedir a aprovação, é necessário <a href="https://admanager.google.com/" rel="noreferrer" target="_blank">criar uma conta no Google AdManager</a>.
                            </p>
                            <p>
                                Após a criação, informe o ID gerado pelo AdManager, também conhecido como <b>Network Code</b> e o email usado no cadastro da conta. Com isso nós adicionamos sua conta à nossa rede para começar a entrega dos anúncios programático. Chamamos este processo de aprovação de <i>Multiple Customer Management</i> ou pela sigla MCM.
                            </p>

                            <FormAdmanager 
                                idAdmanager={idAdmanager} setIdAdmanager={setIdAdmanager}
                                email={email} setEmail={setEmail}
                                hasId={hasId} hasEmail={hasEmail}
                                hasIdFromDB={hasIdFromDB} hasEmailFromDB={hasEmailFromDB}
                            />

                            {hasIdFromDB && hasEmailFromDB ?
                                <Button disabled style={{width: '8rem', height: '2.3rem', marginTop: '1rem', color: 'var(--black)'}} appearance="primary">Enviar</Button>
                            :
                                <Button disabled={loadingMCM} onClick={handleSubmit} style={{width: '8rem', height: '2.3rem', marginTop: '1rem', color: 'var(--black)'}} appearance="primary">{loadingMCM ? <Loader /> : 'Enviar'}</Button>
                            }
                        </div>
                    </section>

                    <SitesArea />
                </div>

                {/* <div className={styles.nextPageButton}>
                    <Button disabled={loadingData} onClick={goToNext} style={{width: '8rem', height: '2.3rem'}} appearance="primary">{loadingData ? <Loader /> : 'Avançar'}</Button>
                </div> */}
            </div>
        </div>
    )
}