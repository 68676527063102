import React from 'react';

import styles from './headerText.module.scss';      // Estilização

export default function HeaderText ({title, children}) {
    return(
        <div className={styles.header}>
            <h1>{title}</h1>
            <p>
                { children }
            </p>
        </div>
    )
}