// Array de categorias
export const categoriaArray = [
    {id: 1, nome: 'Alimentação'},
    {id: 2, nome: 'App'},
    {id: 3, nome: 'Arquitetura'},
    {id: 4, nome: 'Astrologia'},
    {id: 5, nome: 'Automobilismo'},
    {id: 6, nome: 'Automóveis'},
    {id: 7, nome: 'Aviação'},
    {id: 8, nome: 'Bebidas'},
    {id: 9, nome: 'Beleza'},
    {id: 10, nome: 'Bem Estar'},
    {id: 11, nome: 'Biologia'},
    {id: 12, nome: 'Blog'},
    {id: 16, nome: 'Categoria'},
    {id: 17, nome: 'Celebridades'},
    {id: 18, nome: 'Cinema'},
    {id: 19, nome: 'Colchão'},
    {id: 20, nome: 'Cozinha'},
    {id: 21, nome: 'Culinária'},
    {id: 22, nome: 'Cultura'},
    {id: 23, nome: 'Cursos'},
    {id: 24, nome: 'Decoração'},
    {id: 25, nome: 'Design'},
    {id: 26, nome: 'Direito'},
    {id: 28, nome: 'E-sports'},
    {id: 29, nome: 'Ecommerce'},
    {id: 30, nome: 'Economia e Finanças '},
    {id: 31, nome: 'Educação'},
    {id: 32, nome: 'Eletrônicos'},
    {id: 33, nome: 'Empregos'},
    {id: 34, nome: 'Engenharia'},
    {id: 35, nome: 'Ensino'},
    {id: 36, nome: 'Entretenimento'},
    {id: 37, nome: 'Esportes'},
    {id: 38, nome: 'Estilo de vida'},
    {id: 39, nome: 'Estudantes'},
    {id: 40, nome: 'Famosos'},
    {id: 41, nome: 'Feminino'},
    {id: 42, nome: 'Finanças'},
    {id: 43, nome: 'Fofoca'},
    {id: 44, nome: 'Frases'},
    {id: 45, nome: 'Futebol'},
    {id: 46, nome: 'Fórum'},
    {id: 47, nome: 'Games'},
    {id: 48, nome: 'Gastronomia'},
    {id: 48, nome: 'Geek'},
    {id: 50, nome: 'Guia'},
    {id: 51, nome: 'História'},
    {id: 52, nome: 'Horóscopo'},
    {id: 53, nome: 'Humor'},
    {id: 54, nome: 'Imóveis'},
    {id: 55, nome: 'Investimentos'},
    {id: 56, nome: 'Jogos'},
    {id: 57, nome: 'Jovem'},
    {id: 58, nome: 'Lazer'},
    {id: 59, nome: 'LGBT'},
    {id: 60, nome: 'Lifestyle'},
    {id: 61, nome: 'Luxo'},
    {id: 62, nome: 'Marketing'},
    {id: 63, nome: 'Masculino'},
    {id: 64, nome: 'Meio ambiente'},
    {id: 65, nome: 'Moda'},
    {id: 66, nome: 'Música'},
    {id: 67, nome: 'Nota'},
    {id: 68, nome: 'Notícias'},
    {id: 69, nome: 'Outros'},
    {id: 70, nome: 'Pesca'},
    {id: 71, nome: 'Pesquisa'},
    {id: 72, nome: 'Política'},
    {id: 73, nome: 'Portal'},
    {id: 74, nome: 'Profissional'},
    {id: 75, nome: 'Rádio'},
    {id: 76, nome: 'Receitas'},
    {id: 77, nome: 'Rede'},
    {id: 78, nome: 'Regional'},
    {id: 79, nome: 'Religião'},
    {id: 80, nome: 'Revista'},
    {id: 81, nome: 'Saúde'},
    {id: 82, nome: 'Seguros'},
    {id: 83, nome: 'Stock'},
    {id: 84, nome: 'Tecnologia'},
    {id: 85, nome: 'Tempo'},
    {id: 86, nome: 'Transporte'},
    {id: 87, nome: 'Turismo'},
    {id: 88, nome: 'Vegetariano'},
    {id: 89, nome: 'Veículos'},
    {id: 90, nome: 'Viagens'},
];