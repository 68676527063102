import React, { useState, useEffect, useContext } from 'react';

import Cookies from 'universal-cookie';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Steps } from 'rsuite';
import { useMediaQuery } from 'react-responsive';

import Header from './helpers/Header';
import FirstPage from './components/FirstPage';
import SecondPage from './components/SecondPage';
import Approval from './components/Approval';
import NotFound from './components/NotFound';
import ThankYou from './components/ThankYou';
import Login from "./components/Login";

import { SiteInfoContext } from './contexts/SiteInfo';
import { api } from './services/api';

import './App.css';
import "rc-steps/assets/index.css";
import './globals.scss';

export default function App() {
  const cookies = new Cookies();
  let token = cookies.get('token-acesso');

  const { currentStepContext, saveCurrentStep, currentToken } = useContext(SiteInfoContext);

  const isMobile = useMediaQuery({query: '(max-width: 479px)'});      // Largura do aparelho máximo 479px
  const minW480 = useMediaQuery({query: '(min-width: 480px)'});           // Largura de device no mínimo 480px
  const maxW878 = useMediaQuery({query: '(max-width: 978px)'});       // Largura do aparelho máximo 978px

  const [hasUser, setHasUser] = useState(false);

  let location = useLocation();       // Variável que pega o valor de em qual página atualmente o usuário está acessando

  const navigate = useNavigate();

  useEffect(() => {
    if(  currentToken === null && token === undefined ) return;
    api.get(`user/${currentToken ? currentToken : token}`).then((res) => {
      const data  = res.data.result[1];

      if(data) {
        setHasUser(true);

        if(location.pathname === '/') {
          navigate('/preview-dos-formatos');
          saveCurrentStep(1);
        }

        if(location.pathname === '/cadastro-painel-nobeta') {
          navigate('/preview-dos-formatos');
          saveCurrentStep(0);
        }
      } else {
        if(location.pathname === '/preview-dos-formatos') {
          setHasUser(false);

          navigate('/');
          saveCurrentStep(0);
        }

        if(location.pathname === '/cadastro-admanager') {
          navigate('/');
          saveCurrentStep(0);
        }

        if(location.pathname === '/cadastro-painel-nobeta') {
          navigate('/');
          saveCurrentStep(0);
        }
      }
    })
  }, []);

  return(
    <div>
      <Header />

      {location.pathname === '/preview-dos-formatos' || location.pathname === '/cadastro-admanager' || location.pathname === '/cadastro-painel-nobeta' && hasUser ?
        <Steps style={{margin: '2rem 0', padding: isMobile ? '0 1rem' : minW480 && maxW878 ? '0 20%' : '0 30%'}} current={currentStepContext}>
          <Steps.Item />
          <Steps.Item />
          <Steps.Item />
          <Steps.Item />
        </Steps>
        : null
      }

      <Routes>
        <Route path="/" element={<FirstPage />} />

        <Route path="/cadastro-realizado" element={<ThankYou />} />

        <Route path="/preview-dos-formatos" element={<SecondPage />} />

        <Route path="/cadastro-admanager" element={<Approval />} />

        <Route path="/login/:token" element={<Login />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  )
}