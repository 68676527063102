import React, {useState, useEffect, useContext} from 'react';
import {Button, Loader, Modal} from "rsuite";

import validator from "validator";
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { api } from '../../services/api';

import CallbackMessage from "../CallbackMessage";

import styles from './header.module.scss';
import {SiteInfoContext} from "../../contexts/SiteInfo";

export default function Header() {
    const cookies = new Cookies();
    const token = cookies.get('token-acesso');

    // showButton not using
    const { currentToken, saveButtonState } = useContext(SiteInfoContext);

    const navigate = useNavigate();

    const [openLoginModal, setOpenLoginModal] = useState(false);

    const [email, setEmail] = useState('');
    const [hasEmail, setHasEmail] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
    const [openErrorMessage, setOpenErrorMessage] = useState(false);

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    useEffect(() => {
        if(  currentToken === null && token === undefined ) return;
        api.get(`user/${currentToken ? currentToken : token}`).then((res) => {
            const data  = res.data.result[1];

            if(data) {
                saveButtonState(false);
            } else {
                saveButtonState(true);
            }
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (email) {
            setHasEmail(true);

            let isEmail = validator.isEmail(email);

            if(isEmail) {
                setIsValidEmail(true);
            }  else {
                setIsValidEmail(false);
            }
        }
    }, [email]);

    function goToHome() {
        api.get(`user/${token}`).then((res) => {
            const data = res.data.result[0];

            if(data.length > 0) {
                navigate('/preview-dos-formatos');
            } else {
                navigate('/');
            }
        })
    }

    function handleLogin() {
        setLoadingSubmit(true);

        if(email && isValidEmail) {
            api.post('login', {
                email
            }).then((res) => {
                const data = res.data
                setLoadingSubmit(false);

                if(data.result) {
                    setOpenSuccessMessage(true);
                    setSuccessMessage(data.result);
                    console.log(data.result);
                }

                if(data.error) {
                    setOpenErrorMessage(true);
                    setErrorMessage(data.error);
                    console.log(data.error);
                }
            })
        } else {
            if(!email) {
                setHasEmail(false);
                setIsValidEmail(false);
                // setErrorMessage('Todos os campos são obrigatórios');
            } else {
                let isEmail = validator.isEmail(email);

                if(isEmail) {
                    setHasEmail(true);
                    setIsValidEmail(true);
                }  else {
                    setIsValidEmail(false);
                    setHasEmail(false);
                    // setErrorMessage('Email inválido');
                }
            }
        }
    }

    return(
        <div className={styles.header}>
            <CallbackMessage 
                successMessage={successMessage} 
                openSuccessMessage={openSuccessMessage} 
                setOpenSuccessMessage={setOpenSuccessMessage} 
                errorMessage={errorMessage} 
                openErrorMessage={openErrorMessage} 
                setOpenErrorMessage={setOpenErrorMessage}
            />
            <CallbackMessage 
                errorMessage={errorMessage} 
                openErrorMessage={openErrorMessage} 
                setOpenErrorMessage={setOpenErrorMessage} 
                successMessage={successMessage} 
                openSuccessMessage={openSuccessMessage} 
                setOpenSuccessMessage={setOpenSuccessMessage}
            />

            <Modal open={openLoginModal} onClose={() => {
                setOpenLoginModal(false);
            }}>
                <Modal.Header>
                    <Modal.Title style={{fontWeight: 'bold', fontSize: '1.5rem'}}>Já possui uma conta?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className={styles.inputDiv}>
                        <label style={{color: !hasEmail ? 'var(--red)' : null}}>Digite seu email</label>
                        <input type="text" placeholder="email@gmail.com" value={email} onChange={v => setEmail(v.target.value)} style={{borderColor: !hasEmail ? 'var(--red)' : null, width: '100%', marginTop: '.5rem'}}/>
                    </div>

                    <Button disabled={loadingSubmit} onClick={handleLogin} style={{width: '100%', height: '2.3rem', marginTop: '1rem'}} appearance="primary">{loadingSubmit ? <Loader size={"sm"} /> : 'Login'}</Button>
                </Modal.Body>

            </Modal>

            <div className={styles.logoDiv} onClick={goToHome}>
                <img alt="logo" className={styles.logo} src='https://cdn.nobeta.com.br/assets/logo-nobeta-100x32.png' />
            </div>

            {/* {showButton ?
                <div className={styles.haveAnAccount}>
                    <span onClick={() => setOpenLoginModal(true)}>Já tem uma conta?</span>
                </div>
                :
                null
            } */}
        </div>
    )
}