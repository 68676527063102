/** ----------------------- BIBLIOTECAS ----------------------- */
import { Form, SelectPicker, Checkbox, Loader, Button } from 'rsuite';
/** ------------------------------------------------------------ */

/** ----------------------- ICONS ----------------------- */
import VisibleIcon from '@rsuite/icons/Visible';
import UnvisibleIcon from '@rsuite/icons/Unvisible';
import CheckIcon from '@rsuite/icons/Check';  
import ExportIcon from '@rsuite/icons/Export';
/** ----------------------------------------------------- */

import { categoriaArray } from '../helpers/categoriaArray';  // Array de categorias                                           

import styles from './styles.module.scss';
import React from "react";          // Estilização

// Formulário do TOPO na página inicial (FirstPage), para preencher as informações do site
export const FormSiteInfo = ({ dominio, setDominio, categoria, setCategoria, pageviews, setPageview, adsense, setAdsense, hasDominio, hasCategoria, hasPageview, handlePageviewValue, isMobile, maxW635 }) => {
    return(
        <Form className={styles.formSiteInfo}>
            <div className={styles.inputDiv}>
                <label style={{color: !hasDominio ? 'red' : null}}>Domínio (ex: www.site.com)</label>
                <div className={styles.inputWithAppend}>
                    <div className={styles.append}>
                        <span>https://</span>
                    </div>

                    <input type="text" placeholder="Domínio" value={dominio} onChange={v => setDominio(v.target.value)} style={{border: '1px solid transparent', borderColor: !hasDominio ? 'red' : null}} />
                </div>
            </div>

            <div className={styles.inputDiv}>
                <label style={{color: !hasCategoria ? 'red' : null}}>Categoria</label>
                <SelectPicker 
                    data={categoriaArray} 
                    labelKey='nome' 
                    valueKey='nome' 
                    value={categoria}
                    placement={isMobile ? "bottomStart" : "bottomEnd"} 
                    appearance="default" 
                    placeholder={'Categoria'} 
                    onChange={v => setCategoria(v)}
                    cleanable={false}
                    menuMaxHeight={280}
                    style={{ width: maxW635 ? '100%' : '15rem', marginTop: '.3rem', border: '1px solid transparent', borderColor: !hasCategoria ? 'red' : 'transparent', borderRadius: '.4rem'}} 
                />      
            </div>

            <div className={styles.inputDiv}>
                <label style={{color: !hasPageview ? 'red' : null}}>Pageviews</label>
                <input type="text" placeholder="Pageviews" onBlur={handlePageviewValue} value={pageviews ? parseInt(pageviews).toLocaleString('pt-br') : ''} minLength={1} maxLength={12} onChange={v => setPageview(v.target.value.replace(/[^0-9]/g, ""))} style={{borderColor: !hasPageview ? 'red' : null}} />
            </div>

            <div onClick={() => setAdsense(!adsense)} className={styles.inputCheckbox}>
                <div style={{backgroundColor: adsense ? 'var(--green)' : 'var(--white)'}} className={styles.box}>
                    {adsense ?
                        <CheckIcon color="var(--white)" style={{width: '.8rem', height: '.8rem'}} />
                    :
                        null
                    }
                </div>
                <span>Tenho Adsense</span>
            </div>
        </Form>
    )
}

// Formulário de BAIXO na página inicial (FirstPage), para preencher com os dados do usuário
export const FormUserInfo = ({ userName, setUserName, userEmail, setUserEmail, userPhone, handleChangeUserPhone, acceptTerms, setAcceptTerms, hasName, hasEmail, hasPhone, hasAcceptTerms }) => {
    return(
        <Form className={styles.formUserInfo}>  
            <div className={styles.inputDiv}>
                <label style={{color: !hasName ? 'red' : null}}>Nome</label>
                <input type="text" placeholder="Seu Nome" value={userName} onChange={v => setUserName(v.target.value)} style={{border: '1px solid transparent', borderColor: !hasName ? 'red' : null}} />
            </div>

            <div className={styles.inputDiv}>
                <label style={{color: !hasEmail ? 'red' : null}}>Email</label>
                <input type="text" placeholder="Seu Email" value={userEmail} onChange={v => setUserEmail(v.target.value)} style={{border: '1px solid transparent', borderColor: !hasEmail ? 'red' : null}} />
            </div>

            <div className={styles.inputDiv}>
                <label style={{color: !hasPhone ? 'red' : null}}>Telefone</label>
                <input placeholder="Ex: 11 991789599" value={userPhone} onChange={v => handleChangeUserPhone(v.target.value.replace(/[^0-9]/g, ""))} style={{border: '1px solid transparent', borderColor: !hasPhone ? 'red' : null}} />
            </div>

            <div className={styles.terms}>
                <Checkbox checked={acceptTerms} onClick={() => setAcceptTerms(!acceptTerms)}><span style={{color: !hasAcceptTerms ? 'red' : null}}>Li os termos e concordo com a política de privacidade da nobeta</span></Checkbox>
            </div>
        </Form>
    )
}

// Formulário para adicionar um novo side na página 2 (SecondPage)
export const FormAddNewSite = ({ siteName, setSiteName, siteDominio, setSiteDominio, siteCategoria, setSiteCategoria, sitePageviews, setSitePageviews, logoUrl, errorImage, setImage, siteColorTopbar, setSiteColorTopbar, siteColorText, setSiteColorText, loadingSite, handlePageviewValue, addNewSite, hasSiteName, hasSiteDominio, hasSiteCategoria, hasSitePageviews, hasSiteLogo }) => {
    return(
        <Form className={styles.formAddNewSite}>
            <div  className={styles.inputDiv}>
                <label style={{color: !hasSiteName ? 'red' : null}}>Nome</label>
                <input type="text" placeholder="Site" value={siteName} onChange={v => setSiteName(v.target.value)} style={{borderColor: !hasSiteName ? 'red' : null}} />
            </div>

            <div className={styles.inputDiv}>
                <label style={{color: !hasSiteDominio ? 'red' : null}}>Domínio (ex: www.site.com)</label>
                <div className={styles.inputWithAppend}>
                    <div className={styles.append}>
                        <span>https://</span>
                    </div>
                    <input type="text" placeholder="Domínio" value={siteDominio} onChange={v => setSiteDominio(v.target.value)} style={{borderColor: !hasSiteDominio ? 'red' : null}} />
                </div>
            </div>

            <div className={styles.inputDiv}>
                <label style={{color: !hasSiteCategoria ? 'red' : null}}>Categoria</label>
                <SelectPicker 
                    data={categoriaArray} 
                    labelKey='nome' 
                    valueKey='nome' 
                    placement={"bottomStart"} 
                    appearance="default" 
                    placeholder='Categoria' 
                    value={siteCategoria}
                    onChange={v => setSiteCategoria(v)}
                    cleanable={false}
                    menuMaxHeight={280}
                    style={{ width: '100%', marginTop: '.5rem', border: !hasSiteCategoria ? '1px solid red' : null, borderRadius: '.2rem'}} 
                />   
            </div>

            <div className={styles.inputDiv}>
                <label style={{color: !hasSitePageviews ? 'red' : null}}>Pageviews</label>
                <input type="text" placeholder="Pageviews" onBlur={handlePageviewValue} value={sitePageviews ? parseInt(sitePageviews).toLocaleString('pt-br') : ''} minLength={1} maxLength={12} onChange={v => setSitePageviews(v.target.value.replace(/[^0-9]/g, ""))} style={{borderColor: !hasSitePageviews ? 'red' : null}} />
            </div>

            <div className={styles.inputDiv}>
                <label style={{color: !hasSiteLogo || errorImage ? 'red' : null}}>Logo (max: 100x32)</label>

                <label htmlFor='file-input' className={styles.labelForFile}>
                    {logoUrl && !errorImage ?
                        <div>
                            <img alt="logo-in-added" src={logoUrl} />
                        </div>
                    :
                        <>
                            <ExportIcon style={{width: '1.3rem', height: '1.3rem', marginRight: '1rem'}} color="var(--black)" />
                            Escolha uma imagem       
                        </>
                    }
                </label>
                <input id="file-input" type="file" className={styles.fileInput} onChange={e => setImage(e.target)} />
            </div>

            <div className={styles.chooseColor}>
                <div className={styles.wrapColor}>
                    <input onChange={v => setSiteColorTopbar(v.target.value)} value={siteColorTopbar} type="color" className={styles.color} />
                </div>

                <div className={styles.columnColor}>
                    <label>Cor do fundo</label>
                    <input placeholder="Cor 1" onChange={v => setSiteColorTopbar(v.target.value)} value={siteColorTopbar} />
                </div>
            </div>

            <div className={styles.chooseColor}>
                <div className={styles.wrapColor}>
                    <input onChange={v => setSiteColorText(v.target.value)} value={siteColorText} type="color" className={styles.color} />
                </div>

                <div className={styles.columnColor}>
                    <label>Cor da fonte</label>
                    <input placeholder="Cor 2" onChange={v => setSiteColorText(v.target.value)} value={siteColorText} />
                </div>
            </div>

            <div className={styles.addSiteButton}>
                <Button disabled={loadingSite} onClick={addNewSite} style={{width: '8rem', height: '2.3rem', color: 'var(--black)'}} appearance="primary">{loadingSite ? <Loader /> : 'Salvar'}</Button>
            </div>
        </Form>
    )
}

// Formulário de edição de site, aparece como Modal ao clicar em algum site na página 2 (SecondPage)
export const FormEditSite = ({ currentNameEditing, setCurrentNameEditing, currentDominioEditing, setCurrentDominioEditing, currentCategoriaEditing, setCurrentCategoriaEditing, currentPageviewsEditing, setCurrentPageviewsEditing, currentColorTopbarEditing, setCurrentColorTopbarEditing, currentColorTextEditing, setCurrentColorTextEditing, editImage, editLogoUrl, currentLogoEditing, editErrorImage}) => {
    return(
        <form className={styles.editForm}>
            <div className={styles.inputDiv}>
                <label>Nome</label>
                <input className={styles.input} value={currentNameEditing === 'null' ? '' : currentNameEditing} onChange={v => setCurrentNameEditing(v.target.value)} placeholder="Nome" />
            </div>

            <div className={styles.inputDiv}>
                <label>Domínio</label>
                <input className={styles.input} value={currentDominioEditing} onChange={v => setCurrentDominioEditing(v.target.value)} placeholder="Domínio" />
            </div>

            <div className={styles.inputDiv}>
                <label>Categoria</label>
                <SelectPicker 
                    className={styles.input}
                    data={categoriaArray} 
                    labelKey='nome' 
                    valueKey='nome' 
                    placement={"bottomStart"} 
                    appearance="default" 
                    placeholder='Categoria' 
                    value={currentCategoriaEditing}
                    onChange={v => setCurrentCategoriaEditing(v)}
                    cleanable={false}
                    menuMaxHeight={280}
                />   
            </div>

            <div className={styles.inputDiv}>
                <label>Pageviews</label>
                <input className={styles.input} value={currentPageviewsEditing ? parseInt(currentPageviewsEditing).toLocaleString('pt-br') : ''} minLength={1} maxLength={12} onChange={v => setCurrentPageviewsEditing(v.target.value.replace(/[^0-9]/g, ""))} placeholder="Pageviews" />
            </div>

            <div className={styles.inlineInputDiv}>
                <div className={styles.inputDiv}>
                    <label>Logo (max: 100x32)</label>

                    <label htmlFor="file-edit-input" className={styles.labelForFile}>
                        {(editLogoUrl || currentLogoEditing) && !editErrorImage ?
                            <div>
                                <img alt="logo-in-added" src={editLogoUrl ? editLogoUrl : currentLogoEditing} />
                            </div>
                        :
                            <>
                                <ExportIcon style={{width: '1.3rem', height: '1.3rem', marginRight: '1rem'}} color="var(--black)" />
                                Escolha uma imagem       
                            </>
                        }
                    </label>
                    <input className={styles.fileInput} id="file-edit-input" type="file" onChange={e => editImage(e.target)} />
                </div>
            </div>

            <div className={styles.inputDiv}>
                <div className={styles.chooseColor}>
                    <div className={styles.wrapColor}>
                        <input value={currentColorTopbarEditing} onChange={v => setCurrentColorTopbarEditing(v.target.value)} type="color" className={styles.color} />
                    </div>

                    <div className={styles.columnColor}>
                        <label>Cor do fundo</label>
                        <input placeholder="Cor 1" value={currentColorTopbarEditing} onChange={v => setCurrentColorTopbarEditing(v.target.value)} />
                    </div>
                </div>

                <div className={styles.chooseColor}>
                    <div className={styles.wrapColor}>
                        <input value={currentColorTextEditing} onChange={v => setCurrentColorTextEditing(v.target.value)} type="color" className={styles.color} />
                    </div>

                    <div className={styles.columnColor}>
                        <label>Cor da fonte</label>
                        <input placeholder="Cor 2" value={currentColorTextEditing} onChange={v => setCurrentColorTextEditing(v.target.value)} />
                    </div>
                </div>
            </div>
        </form>
    )
}

// Formulário de cadastro dos dados do Admanager
export const FormAdmanager = ({ idAdmanager, setIdAdmanager, email, setEmail, hasId, hasEmail, hasIdFromDB, hasEmailFromDB }) => {
    return(
        <Form className={styles.formAdmanager}>
            <div className={styles.inputDiv}>
                <label style={{color: !hasId ? 'red' : null}}>Id do Admanager</label>
                <input disabled={hasIdFromDB} type="text" placeholder="Id Admanager" value={idAdmanager} onChange={v => setIdAdmanager(v.target.value)} style={{borderColor: !hasId ? 'red' : null}} />
            </div>

            <div className={styles.inputDiv}>
                <label style={{color: !hasEmail ? 'red' : null}}>Email de cadastro Admanager/Adsense:</label>
                <input disabled={hasEmailFromDB} type="email" placeholder="Email Admanager" value={email} onChange={v => setEmail(v.target.value)} style={{borderColor: !hasEmail ? 'red' : null}} />
            </div>
        </Form>
    )
}

// Formulário de cadastro dos dados do Painel Nobeta
export const FormPainelAccount = ({ user, setUser, password, setPassword, confirmPassword, setConfirmPassword, hasUser, hasPassword, hasConfirmPassword, visible1, setVisible1, visible2, setVisible2  }) => {
    return(
        <Form className={styles.formPainelAccount}>
            <div className={styles.inputDiv}>
                <label style={{color: !hasUser ? 'red' : null}}>Usuário</label>
                <input type="text" placeholder="Usuário" value={user} onChange={v => setUser(v.target.value)} style={{borderColor: !hasUser ? 'red' : null, marginTop: '.5rem'}} />
            </div>

            <div className={styles.inputDiv}>
                <label style={{color: !hasPassword ? 'red' : null}}>Senha (min: 6)</label>

                <div style={{borderColor: !hasPassword ? 'red' : null}} className={styles.wrapInput}>
                    <input type={visible1 ? 'text' : 'password'} placeholder="Senha" value={password} onChange={v => setPassword(v.target.value)} />
                    <div onClick={() => setVisible1(!visible1)} className={styles.passwordButton} style={{borderColor: !hasPassword ? 'red' : null}}>
                        {visible1 ? <VisibleIcon /> : <UnvisibleIcon />}
                    </div>
                </div>
            </div>

            <div className={styles.inputDiv}>
                <label style={{color: !hasConfirmPassword ? 'red' : null }}>Confirme a senha</label>

                <div style={{borderColor: !hasConfirmPassword ? 'red' : null}} className={styles.wrapInput}>
                    <input type={visible2 ? 'text' : 'password'} placeholder="Confirme sua senha" value={confirmPassword} onChange={v => setConfirmPassword(v.target.value)} />
                    <div onClick={() => setVisible2(!visible2)} className={styles.passwordButton} style={{borderColor: !hasConfirmPassword ? 'red' : null}}>
                        {visible2 ? <VisibleIcon /> : <UnvisibleIcon />}
                    </div>
                </div>
            </div>
        </Form>
    )
}