import React, { useState, useEffect } from 'react';
import { Loader } from 'rsuite';

import styles from './loading.module.scss';

export default function Loading ({ load }) {
    /** ----------------------------------- Loading de visualização ----------------------------------- */
    const [loading, setLoading] = useState(load ? load : true);
    /** ----------------------------------- ----------------------------------- ----------------------- **/

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1500)
    }, [])

    return(
        <div style={{display: loading ? 'flex' : 'none'}} className={styles.container}>
            {loading ?
                <Loader size="lg" />
            :
                null
            }
        </div>
    )
}