

export const createBlocoFromDominio = (props: string) => {
    let bloco: string;
                        
    let dominio_split = props.split('.');

    if(dominio_split[0] === 'www') {
        bloco = dominio_split[1];
    } else {
        bloco = dominio_split[0];
    }

    return bloco;
}