import React, { useState, useEffect, useContext } from 'react';

/** --------------------- IMPORT DE BIBLIOTECAS --------------------- */
import { Checkbox, Slider, Button, Steps, Loader } from 'rsuite';            
import VisibleIcon from '@rsuite/icons/Visible';     
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import { useNavigate } from 'react-router-dom';                                                
import { useMediaQuery } from 'react-responsive';                                       
import validator from 'validator'                                                        
import Cookies from 'universal-cookie';                                                 
/** --------------------- --------------------- --------------------- **/


/** --------------------- IMPORT DE IMAGENS --------------------- */
import IconIntercontentImg from '../../assets/images/icon_intercontent.png';      
import IconAlertImg from '../../assets/images/icon_alert.png';                    
import IconPreParagraphImg from '../../assets/images/icon_preparagraph.png';     
import IconFooterImg from '../../assets/images/icon_footer.png';
import IconStickyImg from '../../assets/images/icon_sticky.png';
import IconFlyingCarpetImg from '../../assets/images/icon_flyingcarpet.png';
/** --------------------- --------------------- ---------------- **/


/** --------------------- IMPORT DE FUNÇÕES/CONEXÕES PARA SALVAR DADOS --------------------- */
import { SiteInfoContext } from '../../contexts/SiteInfo';
import { api } from '../../services/api';     
/** --------------------- --------------------- --------------------- --------------------- **/


/** --------------------- IMPORT DE HELPERS --------------------- */
import CallbackMessage from '../../helpers/CallbackMessage';
import { questionsArray } from '../../helpers/questionsArray';  
import { createBlocoFromDominio } from '../../helpers/blocoFunction';
import {                                                                                            
    intercontentFillRate, 
    eCpmIntercontent, 
    alertFillRate, 
    eCpmAlert, 
    preParagraphFillRate, 
    eCpmPreParagraph, 
    footerFillRate, 
    eCpmFooter, 
    stickyFillRate, 
    eCpmSticky, 
    flyingFillRate, 
    eCpmFlying, 
    revenueShare 
} from '../../helpers/calculo'; 
/** --------------------- --------------------- ---------------- **/

import { FormSiteInfo, FormUserInfo } from '../../Forms';

// Estilização
import styles from './first_page.module.scss';

type siteData = {
    id: any;
    monitorid: number;
    name: string;
    bloco: string;
    dominio: string;
    categoria: string;
    pageviews: number;
    logo: string;
    status: string;
    bgColor: string;
    fontColor: string;
}

export default function InicialContent() {
    const navigate = useNavigate();
    const cookies = new Cookies();                                          // Variável para armazenar e setar cookies
    const token = cookies.get('token-acesso');

    const { saveCurrentStep, currentToken, saveCurrentToken, saveButtonState, saveData } = useContext(SiteInfoContext);

    /* ------------- Variáveis de responsividade ------------- */
    const isMobile = useMediaQuery({query: '(max-width: 479px)'});          // Largura de device no máximo 479px
    const minW480 = useMediaQuery({query: '(min-width: 480px)'});           // Largura de device no mínimo 480px
    const minW781 = useMediaQuery({query: '(min-width: 781px)'});           // Largura de device no mínimo 750px
    const maxW635 = useMediaQuery({query: '(max-width: 635px)'});           // Largura de device no máximo 749px
    const maxW780 = useMediaQuery({query: '(max-width: 780px)'});           // Largura de device no máximo 749px
    const maxW978 = useMediaQuery({query: '(max-width: 978px)'});           // Largura de device no máximo 978px
    /** ------------- ------------- ------------- ------------- ------------- -------------**/

    /* ------------- Variáives principais que serão salvas no banco de dados ------------- */ 
    const [dominio, setDominio] = useState('');                                 // Dominio do site 
    const [categoria, setCategoria] = useState('');                             // Categoria do site  
    const [pageviews, setPageview] = useState(0);                               // Pageviews do site 
    const [adsense, setAdsense] = useState(false);
    const [site, setSite] = useState([] as unknown as siteData);
    
    const [userName, setUserName] = useState('');                               // Nome do usuário
    const [userEmail, setUserEmail] = useState('');                             // Email do usuário
    const [userPhone, setUserPhone] = useState('');                             // Telefone do usuário
    const [acceptTerms, setAcceptTerms] = useState(false);                      // Termos e serviços

    const [intercontentFormat, setIntercontentFormat] = useState(false);                            // Intercontent
    const [alertFormat, setAlertFormat] = useState(false);                            // Alert
    const [preparagraphFormat, setPreparagraphFormat] = useState(false);                            // Pre Pragraph
    const [footerFormat, setFooterFormat] = useState(false);                            // Footer
    const [stickyFormat, setStickyFormat] = useState(false);                            // Sticky
    const [flyingFormat, setFlyingFormat] = useState(false);                            // Flying Carpet

    const [tradicionalValue, setTradicionalValue] = useState(50);               // Porcentagem de acesso tradicional
    const [ampValue, setAmpValue] = useState(50);                               // Porcentagem de acesso AMP

    const [totalValue, setTotalValue] = useState(0);                            // Valor total dos formatos selecionados
    /** ------------- ------------- ------------- ------------- ------------- -------------**/


    /* ------------- Variáveis de verificação (usado para mostrar visualmente o que o usuário falta preencher) ------------- */
    const [hasDominio, setHasDominio] = useState(true);                         // Verifica se tem dominio digitado
    const [hasCategoria, setHasCategoria] = useState(true);                     // Verifica se tem categoria selecionada
    const [hasPageview, setHasPageview] = useState(true);                       // Verifica se tem pageview digitado 
    const [hasName, setHasName] = useState(true);                               // Verifica se tem Nome digitado
    const [hasEmail, setHasEmail] = useState(true);                             // Verifica se tem Email digitado
    const [hasPhone, setHasPhone] = useState(true);                             // Verifica se tem celular digitado
    const [hasAcceptTerms, setHasAcceptTerms] = useState(true);                 // Verifica se os termos foram aceitos
    const [isValidEmail, setIsValidEmail] = useState(false);                    // Verifica se email é válido
    const [isValidPhone, setIsValidPhone] = useState(false);                    // Verifica se número do telefone é válido
    const [hasExample, setHasExample] = useState(true);                         // Verifica se tem formato selecionado
    /** ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- --**/


    /* ------------- Variáveis dos formatos (inteiros e formatados para REAL) ------------- */ 
    const [intercontentValue, setIntercontentValue] = useState(0);                      // Valor Intercontent
    const [alertValue, setAlertValue] = useState(0);                                    // Valor Alert
    const [preParagraphValue, setPreParagraphValue] = useState(0);                      // Valor Pre Paragraph
    const [footerValue, setFooterValue] = useState(0);                                  // Valor Footer
    const [stickyValue, setStickyValue] = useState(0);                                  // Valor Sticky
    const [flyingValue, setFlyingValue] = useState(0);                                  // Valor Flying Carpet

    const [intercontentFormatedValue, setInterContentFormatedValue] = useState('');      // Valor formatado Intercontent
    const [alertFormatedValue, setAlertFormatedValue] = useState('');                    // Valor formatado Alert
    const [preParagraphFormatedValue, setPreParagraphFormatedValue] = useState('');      // Valor formatado Pre Paragraph
    const [footerFormatedValue, setFooterFormatedValue] = useState('');                  // Valor formatado Footer
    const [stickyFormatedValue, setStickyFormatedValue] = useState('');                  // Valor formatado Sticky
    const [flyingFormatedValue, setFlyingFormatedValue] = useState('');                  // Valor formatado Flying Carpet
    /** ------------- ------------- ------------- ------------- ------------- -------------**/


    /* ------------- Variáveis de mostrar mensagem de erro ------------- */ 
    const [openErrorMessage, setOpenErrorMessage] = useState(false);                            // Abrir mensagem de erro
    const [errorMessage, setErrorMessage] = useState('Todos os campos são obrigatórios');       // Conteúdo da mensagem de erro
    /** ------------- ------------- ------------- ------------- --------**/


    /** ----------------------------------- Loading de visualização ----------------------------------- */
    const [loadingData, setLoadingData] = useState(false);
    /** ----------------------------------- ----------------------------------- ----------------------- **/

    // Verificação de Step ao carregar a página
    useEffect(() => {
        api.get(`user/${token}`).then((res) => {
          const data  = res.data.result[0];

          if(data.length > 0) {
            saveCurrentStep(0);
          } else {
            saveCurrentStep(1);
          }
        })
    }, []);

    // Verificação se as variáveis foram preenchidas (executada toda vez que alguma variável dentro do [] é alterada)
    useEffect(() => {
        if(dominio) {
            setHasDominio(true);
        }

        if (categoria) {
            setHasCategoria(true);
        } 

        if (pageviews) {
            setHasPageview(true);
        } 

        if (intercontentFormat || alertFormat || preparagraphFormat || footerFormat || stickyFormat || flyingFormat) {
            setHasExample(true);
        } 

        if(userName) {
            setHasName(true);
        } 

        if(acceptTerms) {
            setHasAcceptTerms(true);
        }

        setSite({
            id: 0,
            monitorid: 0,
            name: '',
            bloco: '',
            dominio: dominio,
            categoria: categoria,
            pageviews: pageviews,
            logo: '',
            status: 'desativo',
            bgColor: '#FFB300',
            fontColor: '#454849',
        });
    }, [dominio, categoria, pageviews, acceptTerms, userName, intercontentFormat, alertFormat, preparagraphFormat, footerFormat, stickyFormat, flyingFormat]);

    // Verificação se o email foi preenchido, ativada toda vez que a variável userEmail mudar
    useEffect(() => {
        if (userEmail) {
            setHasEmail(true);

            let isEmail = validator.isEmail(userEmail);
    
            if(isEmail) {
                setIsValidEmail(true);
            }  else {
                setIsValidEmail(false);
            }
        } 
    }, [userEmail])

    //  Verificação se Telefone foi preenchido, ativada toda vez que a variável userPhone mudar
    useEffect(() => {
        if(userPhone) {
            setHasPhone(true);

            let isPhone = validator.isMobilePhone(userPhone, ['pt-BR']) 

            if(!isPhone) {
                setIsValidPhone(false);
            } else {
                setIsValidPhone(true);
            }
        }
    }, [userPhone])

    // Variável que aramzena um novo valor aos formatado dependendo do tanto de pageviews e a proporção de acesso que o usuário indicou 
    useEffect(() => {
        let tradicionalPorcentagem = 100 / tradicionalValue;
        let ampPorcentagem = 100 / ampValue;

        let intercontent_calculo = (((pageviews / tradicionalPorcentagem) / (100 / intercontentFillRate)) / 1000) * eCpmIntercontent / revenueShare;
        let alert_calculo = (((pageviews / tradicionalPorcentagem) / (100 / alertFillRate)) / 1000) * eCpmAlert / revenueShare;
        let pre_paragraph_calculo = (((pageviews / tradicionalPorcentagem) / (100 / preParagraphFillRate)) / 1000) * eCpmPreParagraph / revenueShare;
        let footer_calculo = (((pageviews / tradicionalPorcentagem) / (100 / footerFillRate)) / 1000) * eCpmFooter / revenueShare;
        let sticky_calculo = (((pageviews / ampPorcentagem) / (100 / stickyFillRate)) / 1000) * eCpmSticky / revenueShare;
        let flying_calculo = (((pageviews / ampPorcentagem) / (100 / flyingFillRate)) / 1000) * eCpmFlying / revenueShare;
        
        setIntercontentValue(intercontent_calculo);
        setAlertValue(alert_calculo);
        setPreParagraphValue(pre_paragraph_calculo);
        setFooterValue(footer_calculo);
        setStickyValue(sticky_calculo);
        setFlyingValue(flying_calculo);

        setInterContentFormatedValue(intercontent_calculo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}));
        setAlertFormatedValue(alert_calculo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}));
        setPreParagraphFormatedValue(pre_paragraph_calculo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}));
        setFooterFormatedValue(footer_calculo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}));
        setStickyFormatedValue(sticky_calculo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}));
        setFlyingFormatedValue(flying_calculo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}));

        let intercontent_for_total_value = 0; 
        let alert_for_total_value = 0; 
        let pre_paragraph_for_total_value = 0; 
        let footer_for_total_value = 0; 
        let sticky_for_total_value = 0; 
        let flying_for_total_value = 0; 

        if(!intercontentFormat) {
            intercontent_for_total_value = 0;
        } else {
            intercontent_for_total_value = intercontent_calculo;
        }

        if(!alertFormat) {
            alert_for_total_value = 0;
        } else {
            alert_for_total_value = alert_calculo;
        }

        if(!preparagraphFormat) {
            pre_paragraph_for_total_value = 0;
        } else {
            pre_paragraph_for_total_value = pre_paragraph_calculo;
        }

        if(!footerFormat) {
            footer_for_total_value = 0;
        } else {
            footer_for_total_value = footer_calculo;
        }

        if(!stickyFormat) {
            sticky_for_total_value = 0;
        } else {
            sticky_for_total_value = sticky_calculo;
        }
        
        if(!flyingFormat) {
            flying_for_total_value = 0;
        } else {
            flying_for_total_value = flying_calculo;
        }

        setTotalValue(intercontent_for_total_value + alert_for_total_value + pre_paragraph_for_total_value + footer_for_total_value + sticky_for_total_value + flying_for_total_value);

    }, [pageviews, tradicionalValue, ampValue]);

    // Função de Submit
    function handleSubmit() {
        // setLoadingData(true);

        let bloco: string;

        let siteFormId = document.getElementById('siteFormId');
        let exampleFormatId = document.getElementById('exampleFormatId');

        if(dominio) {
            bloco = createBlocoFromDominio(dominio);
        }

        if((dominio && bloco && categoria && pageviews && userName && userEmail && userPhone && acceptTerms && isValidEmail &&  isValidPhone) && (intercontentFormat || alertFormat || preparagraphFormat || footerFormat || stickyFormat || flyingFormat)) {
            // Se tudo foi preenchido e validado corretamente, vem para aqui
            let objectSite = site;
            objectSite.bloco = bloco;

            let random_token = Math.floor(Math.random() * (9999999999999 - 1111111111111));     // Token aleatório de acesso do usuário

            let array = [];         // Array que irá armazenar os formatos selecionados

            /* ----------------- Fazendo um push ao array acima, dependendo de qual Formato foi selecionado ----------------- */
            if(intercontentFormat) {
                array.push('intercontent');
            }
            if(alertFormat) {
                array.push('alert');
            }
            if(preparagraphFormat) {
                array.push('preparagraph');
            }
            if(footerFormat) {
                array.push('footer');
            }
            if(stickyFormat) {
                array.push('sticky');
            }
            if(flyingFormat) {
                array.push('flying');
            }

            let newTokenValue: any;

            // if(currentToken && currentToken !== '0' && currentToken !== 0) {
            if(currentToken && currentToken !== '0') {
                newTokenValue = currentToken;
            } else if (token && token !== 'undefined') {
                newTokenValue = token;
            } else {
                newTokenValue = random_token;
            }

            /** ----------------- ----------------- ----------------- ----------------- ----------------- **/

            const arrayToString = array.toString();             // Transformando array de formatos em String
            const objectToString = JSON.stringify([objectSite]);        // Transformando JSON em String

            // Inserção de dados da página inicial no Banco de Dados
            api.post('save-inicial-info', {
                token: newTokenValue,
                name: userName,
                email: userEmail,
                phone: userPhone,
                tradicional_value: tradicionalValue,
                amp_value: ampValue,
                formatos: arrayToString,
                total_value: totalValue,
                sites: objectToString,
                adsense: adsense

            }).then((res) => {
                const data = res.data;

                saveData(arrayToString, objectToString, totalValue, tradicionalValue, ampValue)
                setLoadingData(false);

                if(data.error) {
                    setOpenErrorMessage(true);
                    setErrorMessage(data.error);
                } else {
                    saveCurrentStep(1);
                    saveCurrentToken(newTokenValue);
                    saveButtonState(false);
                    navigate('preview-dos-formatos');

                    if(!cookies.get('token-acesso')) {
                        cookies.set('token-acesso', newTokenValue, {
                            maxAge: 10000000
                        });
                    }
                }
            });
        } else {
            // Caso tenha algo não preenchido ou inválido, vem para aqui
            setLoadingData(false);
            setOpenErrorMessage(true);

            if(!acceptTerms) {
                setHasAcceptTerms(false);
                setErrorMessage('Você precisa aceitar os termos para avançar');
            } else {
                setHasAcceptTerms(true);
            }

            if(!userPhone) {
                setHasPhone(false);
                setErrorMessage('Todos os campos são obrigatórios');
            } else {
                let isPhone = validator.isMobilePhone(userPhone, ['pt-BR'])

                if(!isPhone) {
                    setHasPhone(false);
                    setIsValidPhone(false);
                    setErrorMessage('Número de telefone inválido');
                } else {
                    setHasPhone(true);
                    setIsValidPhone(true);
                }
            }

            if(!userEmail) {
                setHasEmail(false);
                setIsValidEmail(false);
                setErrorMessage('Todos os campos são obrigatórios');
            } else {
                let isEmail = validator.isEmail(userEmail);

                if(isEmail) {
                    setHasEmail(true);
                    setIsValidEmail(true);
                }  else {
                    setIsValidEmail(false);
                    setHasEmail(false);
                    setErrorMessage('Email inválido');
                }
            }

            if(!intercontentFormat && !alertFormat && !preparagraphFormat && !footerFormat && !stickyFormat && !flyingFormat) {
                exampleFormatId.scrollIntoView();
                setHasExample(false);
                setErrorMessage('Você precisa selecionar um formato');
            } else {
                setHasExample(true);
            }

            if(!dominio) {
                siteFormId.scrollIntoView();
                setHasDominio(false);
                setErrorMessage('Todos os campos são obrigatórios');
            } else {
                if(!bloco) {
                    siteFormId.scrollIntoView();
                    setHasDominio(false);
                    setErrorMessage('Url inválida, tente novamente');
                } else {
                    setHasDominio(true);
                }
            }

            if(!categoria) {
                siteFormId.scrollIntoView();
                setHasCategoria(false);
                setErrorMessage('Todos os campos são obrigatórios');
            } else {
                setHasCategoria(true);
            }

            if(!pageviews) {
                siteFormId.scrollIntoView();
                setHasPageview(false);
                setErrorMessage('Todos os campos são obrigatórios');
            } else {
                setHasPageview(true);
            }

            if(!userName) {
                setHasName(false);
                setErrorMessage('Todos os campos são obrigatórios');
            } else {
                setHasName(true);
            }
        }
    }

    // Aplica máscara ao número de telefone
    function handleChangeUserPhone (value: string) {
        let v = value.replace(/\D/g, "").replace(/(\d{2})(\d)/, "($1) $2").replace(/(\d{5})(\d{4})(\d)/, "$1$2");
        setUserPhone(v);

        // if(value.length <= 10) {
        //     var x = value.replace(/(\d{2})(\d{4})(\d{4})/);
        //     x = '(' + x[1] + ') ' + x[2] + '-' + x[3];
        // } if (value.length === 11) {
        //     var x = value.replace(/(\d{2})(\d{5})(\d{4})/);
        //     x = '(' + x[1] + ') ' + x[2] + '-' + x[3];
        // } if (value.length > 11) {
        //     x = userPhone;
        // }

    }

    // Verificando valor do Slider TradicionalValue e AmpValue que não pode ser maior de 100 ou menor que 0
    function handleSliderValue () {
        if(tradicionalValue > 100) {
            setTradicionalValue(100);
        } else if (tradicionalValue < 0) {
            setTradicionalValue(0)
        }

        if(ampValue > 100) {
            setAmpValue(100);
        } else if (ampValue < 0) {
            setAmpValue(0)
        }
    }

    // Verificando valor das pageviews que não pode ter mais de 9 caracteres (numeros) 
    function handlePageviewValue() {
        /* 
            Explicando o que foi feito porque o TypeScript acusava erro, basicamente tive que passar a pageview para String, depois fazer a divisão dessa string para não passasr de 10 caracteres,
            e depois passando de volta a string para INTEGER e setando na variável Pageviews
        */

        let pageviewToString = pageviews.toString();

        if(pageviews > 9999999999) {
            let pageviewsToInt = pageviewToString.substr(0, 10);
            setPageview(parseInt(pageviewsToInt));
        } else if (pageviews < 0) {
            setPageview(0);
        } else {
            setPageview(Math.round(pageviews))
        }
    }

    // Inserindo valor no Tradicional e o valor proporcional ao AMP
    function handleOnTradicionalValueChange(v) {
        setTradicionalValue(v);
        setAmpValue(100 - v);
    }

    // Inserindo valor no AMP e o valor proporcional ao Tradicional
    function handleOnMobileValueChange(v) {
        setAmpValue(v);
        setTradicionalValue(100 - v);
    }

    /* -------------------- Lidando com os formatos, selecionado e incremetando seu valor ao TotalValue -------------------- */ 
    function handleIntercontent() {
        setIntercontentFormat(!intercontentFormat);

        if(intercontentFormat) {
            setTotalValue(totalValue - intercontentValue);
        } else {
            setTotalValue(totalValue + intercontentValue);
        }
    }

    function handleAlert() {
        setAlertFormat(!alertFormat);

        if(alertFormat) {
            setTotalValue(totalValue - alertValue);
        } else {
            setTotalValue(totalValue + alertValue);
        }
    }

    function handlePreParagraph() {
        setPreparagraphFormat(!preparagraphFormat);

        if(preparagraphFormat) {
            setTotalValue(totalValue - preParagraphValue);
        } else {
            setTotalValue(totalValue + preParagraphValue);
        }
    }

    function handleFooter() {
        setFooterFormat(!footerFormat);

        if(footerFormat) {
            setTotalValue(totalValue - footerValue);
        } else {
            setTotalValue(totalValue + footerValue);
        }
    }

    function handleSticky() {
        setStickyFormat(!stickyFormat);

        if(stickyFormat) {
            setTotalValue(totalValue - stickyValue);
        } else {
            setTotalValue(totalValue + stickyValue);
        }
    }

    function handleFlying() {
        setFlyingFormat(!flyingFormat);

        if(flyingFormat) {
            setTotalValue(totalValue - flyingValue);
        } else {
            setTotalValue(totalValue + flyingValue);
        }
    }
    /** -------------------- -------------------- -------------------- -------------------- -------------------- --------------------**/

    // Component ABOUT
    const AboutComponent = () => (
        <div className={styles.about}>
            <h1>Conheça a Nobeta em números</h1>

            <div className={styles.aboutInline}>
                <div className={styles.aboutItem}>
                    <h2>301</h2>
                    <span>sites parceiros</span>
                </div>

                <div className={styles.aboutItem}>
                    <h2>R$ 5 milhões</h2>
                    <span>repasses para sites</span>
                </div>

                <div className={styles.aboutItem}>
                    <h2>+9 bilhões</h2>
                    <span>anúncios publicitários</span>
                </div>
            </div>
        </div>
    )

    // Component STEPS
    const StepsComponent = () => (
        <div className={styles.stepsArea}>
            <div className={styles.stepsItem}>
                <div className={styles.stepsBall}>
                    <span>1</span>
                </div>

                <span className={styles.text}>Inclua os dados acima</span>
            </div>

            <div className={styles.stepsItem}>
                <div className={styles.stepsBall}>
                    <span>2</span>
                </div>

                <span className={styles.text}>Escolha os formatos</span>
            </div>

            <div className={styles.stepsItem}>
                <div className={styles.stepsBall}>
                    <span>3</span>
                </div>

                <span className={styles.text}>Conheça o potencial de ganho</span>
            </div>
        </div>
    )

    // Component RECEITA
    const ReceitaComponent = () => (
        <div className={styles.receitaArea}>
            <div className={styles.receitaValueArea}>
                <span>Receita estimada total</span>

                <div className={styles.inlineValue}>
                    <span>{totalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}</span>
                    <span>por mês</span>
                </div>
            </div>

            <div className={styles.lineSeparator}></div>
            
            <p>Os valores apresentados são uma estimativa mensal baseada nos dados e médias do mercado brasileiro, outros fatores podem contribuir para que a receita seja maior ou menor que a projeção.</p>
        </div>
    )

    return(
        <div className={styles.container}>
            <CallbackMessage errorMessage={errorMessage} openErrorMessage={openErrorMessage} setOpenErrorMessage={setOpenErrorMessage} successMessage="" openSuccessMessage={false} setOpenSuccessMessage={false} />

            <div id="siteFormId" className={styles.content}>
                <div className={styles.descriptiveTextArea}>
                    <h2>Saiba quanto seu site pode ganhar</h2>

                    <p>Coloque os dados do seu site para ter uma estimativa dos seus ganhos através do uso da tecnologia Nobeta </p>
                </div>

                <FormSiteInfo 
                    dominio={dominio} setDominio={setDominio} 
                    categoria={categoria} setCategoria={setCategoria} 
                    pageviews={pageviews} setPageview={setPageview}
                    adsense={adsense} setAdsense={setAdsense} 
                    hasDominio={hasDominio} hasCategoria={hasCategoria} hasPageview={hasPageview}
                    handlePageviewValue={handlePageviewValue} 
                    isMobile={isMobile} maxW635={maxW635}
                />
            </div>

            <AboutComponent />

            <StepsComponent />

            <Steps style={{margin: '2rem 0', padding: isMobile ? '0 1rem' : minW480 && maxW978 ? '0 20%' : '0 30%'}} current={0}>
                <Steps.Item />
                <Steps.Item />
                <Steps.Item />
                <Steps.Item />
            </Steps>

            <div className={styles.examples}>
                <div className={styles.sliderArea}>
                    <h2>Proporção de acesso entre Tradicional e AMP</h2>

                    <div className={styles.sliderInline}>
                        <div className={styles.sliderItem}>
                            <div className={styles.wrapInput}>
                                <input type="text" onBlur={handleSliderValue} value={tradicionalValue} min={0} max={100} onChange={v => handleOnTradicionalValueChange(v.target.value.replace(/[^0-9]/g, ""))} />    
                                <span>%</span>
                            </div>

                            <div className={styles.sliderVerticalItem}>
                                <span>Tradicional</span>
                                <Slider
                                    progress
                                    value={tradicionalValue}
                                    onChange={value => handleOnTradicionalValueChange(value)}
                                    style={{width: isMobile ? '100%' : minW480 && maxW780 ? '100%' : minW781 && maxW978 ? '100%' : '20rem', marginTop: '.7rem'}}
                                /> 
                            </div>  
                        </div>

                        <div className={styles.sliderItem}>
                            <div className={styles.wrapInput}>
                                <input type="text" onBlur={handleSliderValue} value={ampValue} min={0} max={100} onChange={v => handleOnMobileValueChange(v.target.value.replace(/[^0-9]/g, ""))} />    
                                <span>%</span>
                            </div>

                            <div className={styles.sliderVerticalItem}>
                                <span>AMP</span>
                                <Slider
                                    progress
                                    value={ampValue}
                                    onChange={value => handleOnMobileValueChange(value)}
                                    style={{width: isMobile ? '100%' : minW480 && maxW780 ? '100%' : minW781 && maxW978 ? '100%' : '20rem', marginTop: '.7rem'}}
                                />  
                            </div>                 
                        </div>
                    </div>
                </div>

                <div id="exampleFormatId" className={styles.exampleArea}>
                    <div className={styles.exampleContainer}>
                        <h2>Formatos especiais para navegação tradicional</h2>

                        <div className={styles.exampleGrid}>
                            <div style={{borderColor: !hasExample ? 'red' : intercontentFormat ? '#3498FF' : null}} className={styles.exampleItem}>
                                <div className={styles.exampleHeader}>
                                    <Checkbox onClick={handleIntercontent} checked={intercontentFormat ? true : false} />

                                    <span onClick={handleIntercontent}>Intercontent</span>

                                    <a href="https://exemplo.nobeta.com.br/formatos/intercontent.html" target="_blank" rel="noreferrer" className={styles.showMoreButton}><VisibleIcon style={{width: '1.3rem', height: '1.3rem'}} color="#fff" /> </a>
                                </div>

                                <div onClick={handleIntercontent}  className={styles.imgArea}>
                                    <img alt="intercontent" src={IconIntercontentImg} />
                                </div>

                                <div onClick={handleIntercontent} style={{borderColor: !hasExample ? 'red' : intercontentFormat ? '#3498FF' : null}} className={styles.exampleFooter}>
                                    <span>Ganho estimado mensal</span>
                                    <span>{intercontentFormatedValue  ? intercontentFormatedValue : 'R$ 0,00'}</span>
                                </div>
                            </div>

                            <div style={{borderColor: !hasExample ? 'red' : alertFormat ? '#3498FF' : null}} className={styles.exampleItem}>
                                <div className={styles.exampleHeader}>
                                    <Checkbox onClick={handleAlert} checked={alertFormat ? true : false} />

                                    <span onClick={handleAlert}>Alert</span>

                                    <a href="https://exemplo.nobeta.com.br/formatos/alert.html" target="_blank" rel="noreferrer" className={styles.showMoreButton}><VisibleIcon style={{width: '1.3rem', height: '1.3rem'}} color="#fff" /> </a>
                                </div>

                                <div onClick={handleAlert} className={styles.imgArea}>
                                    <img alt="alert" src={IconAlertImg} />
                                </div>

                                <div onClick={handleAlert} style={{borderColor: !hasExample ? 'red' : alertFormat ? '#3498FF' : null}}  className={styles.exampleFooter}>
                                    <span>Ganho estimado mensal</span>
                                    <span>{alertFormatedValue ? alertFormatedValue : 'R$ 0,00'}</span>
                                </div>
                            </div>

                            <div style={{borderColor: !hasExample ? 'red' : preparagraphFormat ? '#3498FF' : null}} className={styles.exampleItem}>
                                <div className={styles.exampleHeader}>
                                    <Checkbox onClick={handlePreParagraph} checked={preparagraphFormat ? true : false} />

                                    <span onClick={handlePreParagraph}>Pre-Paragraph</span>

                                    <a href="https://exemplo.nobeta.com.br/formatos/preparagraph.html" target="_blank" rel="noreferrer" className={styles.showMoreButton}><VisibleIcon style={{width: '1.3rem', height: '1.3rem'}} color="#fff" /> </a>
                                </div>

                                <div onClick={handlePreParagraph} className={styles.imgArea}>
                                    <img alt="pre-paragraph" src={IconPreParagraphImg} />
                                </div>

                                <div onClick={handlePreParagraph} style={{borderColor: !hasExample ? 'red' : preparagraphFormat ? '#3498FF' : null}} className={styles.exampleFooter}>
                                    <span>Ganho estimado mensal</span>
                                    <span>{preParagraphFormatedValue ? preParagraphFormatedValue : 'R$ 0,00'}</span>
                                </div>
                            </div>

                            <div style={{borderColor: !hasExample ? 'red' : footerFormat ? '#3498FF' : null}} className={styles.exampleItem}>
                                <div className={styles.exampleHeader}>
                                    <Checkbox onClick={handleFooter} checked={footerFormat ? true : false} />

                                    <span onClick={handleFooter}>Footer</span>

                                    <a href="https://exemplo.nobeta.com.br/formatos/ft.html" target="_blank" rel="noreferrer" className={styles.showMoreButton}><VisibleIcon style={{width: '1.3rem', height: '1.3rem'}} color="#fff" /> </a>
                                </div>

                                <div onClick={handleFooter} className={styles.imgArea}>
                                    <img alt="footer" src={IconFooterImg} />
                                </div>

                                <div onClick={handleFooter} style={{borderColor: !hasExample ? 'red' : footerFormat ? '#3498FF' : null}} className={styles.exampleFooter}>
                                    <span>Ganho estimado mensal</span>
                                    <span>{footerFormatedValue ? footerFormatedValue : 'R$ 0,00'}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.exampleContainer}>
                        <h2>Formatos a especiais para navegação AMP</h2>

                        <div className={styles.exampleGrid}>

                            <div style={{borderColor: !hasExample ? 'red' : stickyFormat ? '#3498FF' : null}} className={styles.exampleItem}>
                                <div className={styles.exampleHeader}>
                                    <Checkbox onClick={handleSticky} checked={stickyFormat ? true : false} />

                                    <span onClick={handleSticky}>Sticky</span>

                                    <a href="https://exemplo.nobeta.com.br/formatos/intercontent.html" target="_blank" rel="noreferrer" className={styles.showMoreButton}><VisibleIcon style={{width: '1.3rem', height: '1.3rem'}} color="#fff" /> </a>
                                </div>

                                <div onClick={handleSticky} className={styles.imgArea}>
                                    <img alt="sticky" src={IconStickyImg} />
                                </div>

                                <div onClick={handleSticky} style={{borderColor: !hasExample ? 'red' : stickyFormat ? '#3498FF' : null}} className={styles.exampleFooter}>
                                    <span>Ganho estimado mensal</span>
                                    <span>{stickyFormatedValue ? stickyFormatedValue : 'R$ 0,00'}</span>
                                </div>
                            </div>

                            <div style={{borderColor: !hasExample ? 'red' : flyingFormat ? '#3498FF' : null}} className={styles.exampleItem}>
                                <div className={styles.exampleHeader}>
                                    <Checkbox onClick={handleFlying} checked={flyingFormat ? true : false} />

                                    <span onClick={handleFlying}>FlyingCarpet</span>

                                    <a href="https://exemplo.nobeta.com.br/formatos/alert.html" target="_blank" rel="noreferrer" className={styles.showMoreButton}><VisibleIcon style={{width: '1.3rem', height: '1.3rem'}} color="#fff" /> </a>
                                </div>

                                <div onClick={handleFlying} className={styles.imgArea}>
                                    <img alt="sticky" src={IconFlyingCarpetImg} />
                                </div>

                                <div onClick={handleFlying} style={{borderColor: !hasExample ? 'red' : flyingFormat ? '#3498FF' : null}}  className={styles.exampleFooter}>
                                    <span>Ganho estimado mensal</span>
                                    <span>{flyingFormatedValue ? flyingFormatedValue : 'R$ 0,00'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ReceitaComponent />

            {/* <div className={styles.questionsArea}>
                <h2>Perguntas frequentes</h2>

                <div className={styles.questionsInline}>
                    {questionsArray.map((item, k) => (
                        <div style={{
                            height: questionToOpen === item.id ? '15rem' : '3.5rem',
                            alignItems: questionToOpen === item.id ? 'flex-start' : 'center'
                        }} onClick={questionToOpen === item.id ? () => setQuestionToOpen('') : () => setQuestionToOpen(item.id)} className={styles.questionsItem}>
                            <div className={styles.questionsHeader}>
                                <span>{item.question}</span>
                            
                                <div style={{transform: questionToOpen === item.id ? 'rotate(90deg)' : null}} className={styles.icon}>
                                    <ArrowRightLineIcon color="var(--text)" style={{width: '1.5rem', height: '1.5rem'}} />
                                </div>
                            </div>
                            
                            <div style={{height: questionToOpen === item.id ? '11.5rem' : 0, display: questionToOpen === item.id ? 'flex' : 'none'}} className={styles.questionsBody}>
                                <p>{item.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div> */}

            <div className={styles.confirmationArea}>
                <div className={styles.textArea}>
                    <h2>Gostaria de seguir para os passos de implementação?</h2>
                    <p>Insira seus dados e clique em avançar, não será preciso ter conhecimentos técnicos nesta etapa. O processo para geração do código será online e em 2 etapas iniciais. Caso precise de ajuda, basta durante a navegação acionar nosso time pelo chat online.</p>
                </div>

                <div className={styles.formWrap}>
                    <FormUserInfo 
                        userName={userName} setUserName={setUserName} 
                        userEmail={userEmail} setUserEmail={setUserEmail} 
                        userPhone={userPhone} handleChangeUserPhone={handleChangeUserPhone} 
                        acceptTerms={acceptTerms} setAcceptTerms={setAcceptTerms} 
                        hasName={hasName} hasEmail={hasEmail} hasPhone={hasPhone} hasAcceptTerms={hasAcceptTerms}
                    />
                    
                    <Button disabled={loadingData} onClick={handleSubmit} style={{width: '8rem', height: '2.3rem', marginTop: '.7rem', color: 'var(--black)'}} color="yellow" appearance="primary">{loadingData ? <Loader size="sm" /> : 'Avançar'}</Button>
                </div>
            </div>
        </div>
    )
}