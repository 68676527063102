import React, {useContext, useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {SiteInfoContext} from "../../contexts/SiteInfo";

export default function Login () {
    const { saveCurrentStep, saveCurrentToken } = useContext(SiteInfoContext);

    const params = useParams();

    const navigate = useNavigate();

    // Redireciona para a página 2, depois de fazer o login
    useEffect(() => {
        saveCurrentToken(params.token);
        saveCurrentStep(1);

        navigate('/preview-dos-formatos');

        setTimeout(() => {
        }, 1500)
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div>
            <span>{params.token}</span>
        </div>
    )
}