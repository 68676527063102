import axios from 'axios';

export const api = axios.create({
    baseURL: 'https://hmreport.nobeta.com.br/api/'
});

// baseURL: 'https://hmreport.nobeta.com.br/api/'

export const apiLocal = axios.create({
    baseURL: 'http://localhost:8000/api/'
})